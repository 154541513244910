import React, { useEffect, useState } from 'react';
import Table from "./Table";
import LoadingAnimations from "../Loaders/Skeleton";
import { dateTimeToString } from "../../utilities/dateTime";

const columns = [
  { Header: "Time", accessor: "time", minWidth: 140 },
  { Header: "User", accessor: "User.Name", minWidth: 170 },
  { Header: "Description", Cell: props => props.row.original.Description.replaceAll("\\n"," ") }
];


export default function InspectionHistoryTable({ items }) {

  const [data, setData] = useState(null);

  useEffect(() => {
    if (items === null) {
      return;
    }

    // Append Download and Delete buttons
    const newItems = items.map((item) => {
      item.time = dateTimeToString(new Date(item.HistoryTime));
      if (item.User.Id === 0) {
        item.User.Name = "System";
      }
      return item;
    });
    setData(newItems);
  }, [items]);


  if (data === null) {
    return <LoadingAnimations height={40}/>;
  }

  return (
    <Table columns={columns} data={data}/>
  );
}