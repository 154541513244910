import React, { useState, useEffect } from "react";
import { config } from "../core";
import { apiProvider } from "../api/core/provider";
import ProfilePersonalInfoCard from "../components/cards/ProfilePersonalInfoCard";
import ProfileAttachmentsTrainingCard from "../components/cards/ProfileAttachmentsTrainingCard";

const userProfile = () => {
  let sectionStyles = "tw-bg-white tw-pb-1 tw-pl-16 tw-shadow";
  let headerStyles = "tw-bg-white tw-py-4 tw-px-16 tw-text-3xl tw--ml-16 tw-font-black";
  
  const userId = config.routeParams.userId;
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const response = await apiProvider.get("users", userId);

    console.log({ response });
    setUser(response.data);
    setLoading(false);
  };

  if (loading) {
    return "Loading user...";
  }

  return (
    <article>
      <ProfilePersonalInfoCard user={user} />
      <ProfileAttachmentsTrainingCard />
    </article>
  );
};

export default userProfile;
