/* eslint-disable react/display-name */

import React, { useEffect, useMemo, useState } from 'react';
import useSWR from "swr";
import { config } from "../core";
import Table from "../components/tables/Table";
import { dateToString } from "../utilities/dateTime";
import { DateTime } from "luxon";
import formatMoney from "../utilities/formatMoney";
import InvoiceTable from "../components/tables/InvoiceTable";
import { Button } from "@material-ui/core";

export default function InspectorInvoices() {

  const [invoices, setInvoices] = useState(null);
  const [fixedData, setFixedData] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [popOverAnchor, setPopOverAnchor] = useState(null);
  const [popOverItem, setPopOverItem] = useState(null);

  const inspectionCountClicked = (target,item) => {
    setPopOverItem(item);
    setPopOverAnchor(target);
  };

  const getInvoiceDetailUrl = id => {
    return `${config.api.baseUrl}/accounting/inspectorinvoices/${id}`;
  };

  const cols = useMemo(() => [
    { Header: "Inspector", accessor: "User.Name" },
    { Header: "Invoice No", accessor: "InvoiceNo" },
    { Header: "Period", accessor: "period", sortType: (rowA, rowB, id, desc) => {
      const a = new Date(rowA.original.Start);
      const b = new Date(rowB.original.Start);
      if (a < b) {
        return -1;
      } else if (a>b) {
        return 1;
      } else {
        return 0;
      }},
    },
    { Header: "# Insp", accessor: "InspectionCount", Cell: props => {
      return (
        <>
          {!props.row.original.Filename ?
            <span className="tw-cursor-pointer"
              onClick={(e) => inspectionCountClicked(e.target, props.row.original)}>{props.row.original.InspectionCount}</span>
            : <span>{props.row.original.InspectionCount}</span>
          }
        </>
      );
    } },
    { Header: "Total", accessor: "amount", sortType: (rowA, rowB, id, desc) => {
      const a = new Date(rowA.original.Total);
      const b = new Date(rowB.original.Total);
      if (a < b) {
        return -1;
      } else if (a>b) {
        return 1;
      } else {
        return 0;
      }} },
    { Header: "Created", accessor: "created", sortType: (rowA, rowB, id, desc) => {
      const a = new Date(rowA.original.GeneratedTime);
      const b = new Date(rowB.original.GeneratedTime);
      if (a < b) {
        return -1;
      } else if (a>b) {
        return 1;
      } else {
        return 0;
      }
    } },
    { Header: "Download", accessor: "", disableSortBy: true, Cell: props => {
      const url = `/accounting/inspectorinvoices/download/${props.row.original.Id}`;
      return (
        <div className={"tw-flex tw-gap-8 tw-text-xl"}>
          <a className={"tw-text-red-400"} href={`${url}?pdf`} title="Download PDF"><i className="fal fa-file-pdf"/></a>
          {props.row.original.Filename.length===0 && <a className={"tw-text-green-500"} href={`${url}?xls`} title="Download Excel"><i className="fal fa-file-excel"/></a>}
        </div>
      );
    } },
  ], []);

  const dataLoaded = (data, key, config) => {
    if (!data && !data.result) {
      return;
    }

    setInvoices(data.result);
  };

  useSWR(`${config.api.baseUrl}/accounting/inspectorinvoices`, { onSuccess: dataLoaded });

  const handleYearChange = year => {
    setYearFilter(year);
  };

  useEffect(() => {
    if (!invoices) {
      return;
    }
    setFixedData(invoices.map((invoice) => {
      invoice.created = dateToString(new Date(invoice.GeneratedTime));
      invoice.amount = formatMoney(Number(invoice.Total)/100);
      invoice.period = DateTime.fromISO(invoice.Start).plus({ hours: 12 }).startOf("day").toLocaleString({ month: 'long', year: 'numeric' });
      return invoice;
    }).filter(invoice => {
      if (!yearFilter) {
        return true;
      }
      return yearFilter === DateTime.fromISO(invoice.Start).get("year");
    }));

  }, [invoices, yearFilter]);

  return (
    <>
      <div className="tw-float-right"> <Button color='primary' variant={'contained'} onClick={() => window.location = '/accounting/inspectorinvoices/generate'}>Create Live Invoice</Button></div>
      <h1>Inspector Invoices</h1>
      {fixedData &&
            <InvoiceTable columns={cols} data={fixedData} yearChange={handleYearChange} getInvoiceDetailUrl={getInvoiceDetailUrl} popOverData={popOverItem} popOverAnchorElement={popOverAnchor} handlePopoverClose={() => setPopOverAnchor(null)}/>
      }
    </>
  );


}