import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { config } from "~/core";
import Table from "../components/tables/Table";
import InspectionPreAddNoteModal from "../components/Modal/InspectionPreAddNote";
import { apiProvider } from "../api/core/provider";
import EditInspectionPriceModal from "../components/Modal/EditInspectionPrice";
import axios from "axios";
import { inspections } from "../api/inspections";
import {
  Checkbox, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel, InputAdornment,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { CancelRounded, SaveRounded } from "@material-ui/icons";
import formatMoney from "../utilities/formatMoney";

export default function InspectionSupplements() {

  const BASE_URL = "/api/" + config.api.version;

  const [priceTable, setPriceTable] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);

  const savePrice = () => {
    apiProvider.post(`accounting/supplements`,
      {
        type: editingPrice.Key,
        price: Number(editingPrice.Price),
      }).then((data) => {
      mutate(`${BASE_URL}/accounting/supplements`);
      setEditingPrice(null);
    }
    ).catch((e) => {
      console.log("Error saving price - ",e);
    });
  };

  const cols = [
    { Header: "Supplement", accessor: "Supplement" },
    { Header: "Price", accessor: "FormattedPrice" },
    { Header: "", accessor: "edit" },
  ];

  const updateEditingPrice = (newPrice) => {
    setEditingPrice(prevState => {
      return { ...prevState, Price: newPrice };
    });
  };

  const makeSupplementConfig = (prices, name, key, desc, isMoney) => {
    let data = {
      Key: key,
      Supplement: name,
      Price: Number(prices[key]),
      FormattedPrice: isMoney ? formatMoney(Number(prices[key]), undefined, undefined, undefined) : prices[key],
      Description: desc,
      IsMoney: isMoney,
    };
    data.edit = <button
      className="btn btn-primary"
      onClick={() => setEditingPrice(data)}
    >
      Edit
    </button>;
    return data;
  };

  const dataLoaded = (data, key, config) => {
    if (!data || !data.result) {
      return;
    }
    let prices = JSON.parse(JSON.stringify(data.result));

    let formattedPrices = [];

    formattedPrices.push(makeSupplementConfig(prices, "Starting Coverage","StartingCoverage","Coverage A value where supplements start to be applied",true));
    formattedPrices.push(makeSupplementConfig(prices, "Starting Price","StartingCost","First supplement price to add",true));
    formattedPrices.push(makeSupplementConfig(prices, "Coverage Interval","CoverageInterval","Which interval of increasing Coverage A to apply further supplements",true));
    formattedPrices.push(makeSupplementConfig(prices, "Interval Price","IntervalCost","What supplement to apply to increasing intervals",true));
    formattedPrices.push(makeSupplementConfig(prices, "Max Price","MaxCost","Cap of supplement pricing",true));
    formattedPrices.push(makeSupplementConfig(prices, "Starting square footage","StartingSqft","Square footage size to start supplement pricing",false));
    formattedPrices.push(makeSupplementConfig(prices, "Starting square footage cost","StartingSqftCost","First supplement price to add",true));
    formattedPrices.push(makeSupplementConfig(prices, "Square footage interval","SqFtInterval","Which interval of increasing Square Footage to apply further supplements",false));
    formattedPrices.push(makeSupplementConfig(prices, "Square footage interval cost","IntervalCostSqft","What supplement to apply to increasing Square Footage",true));

    setPriceTable(formattedPrices);
  };
  useSWR(() => `${BASE_URL}/accounting/supplements`, { onSuccess: dataLoaded });

  return (
    <>
      <h1>Inspection Price Supplements</h1>

      {priceTable &&
            <div className={"tw-mt-14 tw-mb-10"}>
              <Table data={priceTable} columns={cols}/>
            </div>
      }

      <Dialog open={editingPrice!==null} onClose={() => setEditingPrice(null)} maxWidth='sm' fullWidth={true}>

        {editingPrice &&
        <DialogContent>
          <p className={"tw-text-xl"}>{editingPrice.Supplement}</p>
          <p className={"tw-text-sm tw-text-gray-700 tw-mt-1 tw-mb-6"}>{editingPrice.Description}</p>

          <div className="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
            {editingPrice.IsMoney && <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
              <span className="tw-text-gray-500 sm:tw-text-sm">$</span>
            </div>}
            <input
              type="text"
              className="focus:tw-ring-primary focus:tw-border-primary tw-block tw-w-full tw-pl-7 tw-pr-12 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
              aria-describedby="price-currency"
              value={editingPrice.Price}
              onChange={(e) => updateEditingPrice(e.target.value)}
            />
            {editingPrice.IsMoney && <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
              <span className="tw-text-gray-500 sm:tw-text-sm" id="price-currency">
            USD
              </span>
            </div>}
          </div>

          <DialogActions className='sm:mt-4'>
            <Button variant={"contained"} onClick={() => savePrice()} color={"primary"} startIcon={<SaveRounded/>}>
              Save
            </Button>
            <Button variant={"contained"} onClick={() => setEditingPrice(null)} color={"secondary"}
              startIcon={<CancelRounded/>}>
              Cancel
            </Button>
          </DialogActions>

        </DialogContent>
        }

      </Dialog>

    </>
  );
}