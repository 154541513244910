import React, { Fragment, useMemo } from 'react';

export default function FSButton({ icon, iconRight, color = "primary", className, disabled, onClick, children, size = "regular", type = 'button', submitting }) {

  const colorClasses = useMemo(() => {

    let text = "tw-text-white";
    let background;
    let hover;
    let ring;

    if (disabled) {
      return "tw-bg-gray-300";
    }

    switch (color) {
    case "secondary":
      background = "tw-bg-white tw-text-primary-500 tw-border-primary-500";
      if (onClick || type==="submit") {
        hover = "hover:tw-bg-primary-400 hover:tw-text-white";
        ring = "focus:tw-ring-primary-300";
      }
      break;
    case "green":
      background = "tw-bg-fs-green";
      if (onClick || type==="submit") {
        hover = "hover:tw-bg-fs-green-dark";
        ring = "focus:tw-ring-fs-green-light";
      }
      text = "tw-text-gray-100";
      break;
    case "amber":
      background = "tw-bg-fs-amber";
      if (onClick || type==="submit") {
        hover = "hover:tw-bg-fs-fs-amber-700";
        ring = "focus:tw-ring-fs-amber-300";
      }
      text = "tw-text-gray-100";
      break;
    case "red":
    case "danger":
      background = "tw-bg-fs-red";
      if (onClick || type==="submit") {
        hover = "hover:tw-bg-fs-red-dark";
        ring = "focus:tw-ring-fs-red-light";
      }
      break;
    case "white":
      background = "tw-bg-white tw-text-gray-700";
      if (onClick || type==="submit") {
        hover = "hover:tw-bg-primary hover:tw-text-white";
        ring = "focus:tw-ring-fs-primary";
      }
      break;
    case "primary":
    default:
      background = "tw-bg-primary";
      if (onClick || type==="submit") {
        hover = "hover:tw-bg-primary-700";
        ring = "focus:tw-ring-primary-300";
      }
      break;
    }

    return `${background} ${hover} ${ring} ${text}`;

  }, [color,disabled]);


  if(submitting){
    // Always disabled the button when loading
    disabled = true;
  }

  const sizeLarge = 'tw-my-4 tw-px-8 tw-py-4 tw-shadow-md tw-text-lg md:tw-text-md tw-rounded-lg';
  const sizeRegular = 'tw-my-2 tw-px-4 tw-py-2 tw-shadow-sm tw-text-sm md:tw-text-sm tw-rounded-md';
  const sizeCompact = 'tw-my-2 tw-px-2 tw-py-2 tw-shadow-sm tw-text-sm md:tw-text-sm tw-rounded-md';
  const sizeSmall = 'tw-my-0 tw-px-2 tw-py-2 tw-shadow-sm tw-text-xs md:tw-text-xs tw-rounded-md';
  const sizeXSmall = 'tw-my-0 tw-px-1 tw-py-1 tw-shadow-sm tw-text-xs md:tw-text-xs tw-rounded-md';

  return (
    <button type={type} disabled={disabled} onClick={(e) => onClick && onClick(e)}
      className={`${size === 'large' ? sizeLarge : ''} ${size === 'regular' ? sizeRegular : ''} ${size === 'compact' ? sizeCompact : ''} ${size === 'small' ? sizeSmall : ''} ${size === "xsmall" ? sizeXSmall : ''}  tw-border tw-font-medium 
                focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 
                tw-transform tw-ease-in tw-duration-200 ${colorClasses} ${className} ${!onClick || disabled ? "tw-cursor-default" : ""}`}>
      <div className={"tw-flex tw-items-center tw-justify-center"}>

        {submitting && <div className="tw-flex tw-justify-center tw-absolute">
          <svg className="tw-animate-spin tw-h-5 tw-w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div> }

        <span className={submitting ? "tw-invisible" : ""}>
          {icon && <span className={"tw--ml-1 tw-mr-2 tw-h-5 tw-w-5"}>{icon}</span>}
          <span className="tw-uppercase" >{children}</span>
          {iconRight && <span className={"tw-ml-2 tw-h-5 tw-w-5"}>{iconRight}</span>}
        </span>

      </div>

    </button>
  );
}


