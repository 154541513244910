import React from "react";

function ColorKey({ colors }) {
  return (
    <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-4">
      <p>Key: </p>
      {colors.map((item, idx) => {
        return (
          <div key={idx} className="tw-flex tw-items-center">
            <div className={`tw-w-8 tw-h-8 tw-mr-2 tw-rounded tw-border-2 ${item.shape==="circle" ? "tw-rounded-full" : ""}`} style={{ backgroundColor: item.color }}></div>
            <span>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
}

export default ColorKey;