import { dateToString } from "../../utilities/dateTime";
import { Button, Dialog, DialogActions, DialogContent, TextareaAutosize } from "@material-ui/core";
import { CancelRounded, MoreVert, SaveRounded } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { postNewNote } from "../../utilities/inspections";
import { toast } from "react-toastify";
import DashboardCard from "../cards/DashboardCard";
import InspectionHistoryTable from "./InspectionHistoryTable";
import { useSortBy, useTable } from "react-table";
import { apiProvider } from "../../api/core/provider";
import LoadingAnimations from "../Loaders/Skeleton";
import InspectionPreContact from "../../pages/InspectionPreContact";

const Table = ({ columns, data, initialSort = "CreatedAt" }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns, data, initialState: {
      sortBy: [
        {
          id: initialSort,
          desc: false
        }
      ]
    },
  }, useSortBy);
  return (
    <div className={"tw-overflow-x-auto"}>
      <table
        className="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-text-sm "
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, i) =>
            <tr key={`${i}`}
              className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) =>
                <th key={`${i}-${j}`} width={column.minWidth > 0 ? column.minWidth : null}
                  className={`tw-px-6 tw-py-3 tw-text-left tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider tw-font-bold 
                      ${column.isSorted ? column.isSortedDesc ? `sort-desc` : `sort-asc` : ``}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")} {
                    column.isSorted
                      ? column.isSortedDesc
                        ?
                        <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-down"/>
                        </div>
                        : <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-up"/>
                        </div>
                      : ''
                  }
                </th>
              )}
            </tr>
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={`${i}`}{...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  let classnames = "tw-px-6 tw-py-2 ";
                  i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                  return (
                    <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default function InspectionReportTable({ data, mutate, initialSort, showAttempts = false }) {

  const [inspectionHistory, setInspectionHistory] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [inspectionSelected, setInspectionSelected] = useState({});
  const [statusFilter, setStatusFilter] = useState("All");
  const [carrierFilter, setCarrierFilter] = useState("All");
  const [stateFilter, setStateFilter] = useState("All");
  const [appointmentFilter, setAppointmentFilter] = useState("All");
  const [newNote, setNewNote] = useState(null);

  const hasOrderID = useMemo(() => {
    if (!data || data.length===0) {
      return false;
    }
    return data[0].OrderID!==undefined;
  }, [data]);

  const columns = [
    {
      Header: "Policy", accessor: "PolicyNo",
      Cell: props => <a target={"_blank"} href={"/inspections/" + props.row.original.PolicyNo} rel="noreferrer">{props.row.original.PolicyNo}</a>
    },
    { Header: "Insured", accessor: "Insured" },
    { Header: "Address", accessor: "Address" },
    { Header: "City", accessor: "City" },
    { Header: "State", accessor: "State" },
    { Header: "Carrier", accessor: "Carrier" },
    {
      Header: "Received",
      accessor: "CreatedAt",
      Cell: props => dateToString(new Date(props.row.original.CreatedAt))
    },
    {
      Header: "Effective",
      accessor: "EffectiveDate",
      Cell: props => dateToString(new Date(props.row.original.EffectiveDate))
    },
    { Header: "Status", accessor: "Status" },
    { Header: "# History", accessor: "HistoryCount",
      Cell: props => <a href={"#"} onClick={() => showInspectionClick(props.row.original)} rel="noreferrer">{props.row.original.HistoryCount}</a> },
    { Header: "Last Note", accessor: "LastHistory" },
    {
      Header: "Last Contact",
      accessor: "LastHistoryTime",
      Cell: props => dateToString(new Date(props.row.original.LastHistoryTime))
    },
    { Header: "Appt", Cell: props => props.row.original.AppointmentSet ? dateToString(new Date(props.row.original.Appointment)) : "--" }
  ];

  hasOrderID && columns.unshift({ Header: "#", accessor: "OrderID" });

  const getInspectionHistory = policyNo => {
    setInspectionHistory(null);
    apiProvider.get("inspection/" + policyNo + "/history", undefined).then(r => {
      let history = r.data.result;
      history.sort((a, b) => {
        if (new Date(a.HistoryTime) < new Date(b.HistoryTime)) {
          return 1;
        } else if (new Date(a.HistoryTime) > new Date(b.HistoryTime)) {
          return -1;
        }
        return 0;
      });
      setInspectionHistory(history);
    });
  };

  const showInspectionClick = (inspection) => {
    // getInspectionHistory(inspection.PolicyNo);
    setInspectionSelected(inspection);
    setInfoDialogOpen(true);
  };

  const isFilterSet = useMemo(() => {
    return carrierFilter!=="All" || statusFilter!=="All" || stateFilter!=="All" || appointmentFilter!=="All";
  }, [carrierFilter, statusFilter, stateFilter, appointmentFilter]);

  const inspectionsInTable = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.filter(item => {
      let include = true;
      if (carrierFilter && carrierFilter !=="All") {
        include = include && item.Carrier === carrierFilter;
      }
      if (statusFilter && statusFilter !=="All") {
        include = include && item.Status === statusFilter;
      }
      if (stateFilter && stateFilter !=="All") {
        include = include && item.State === stateFilter;
      }
      if (appointmentFilter && appointmentFilter !=="All") {
        if (appointmentFilter==="Yes") {
          include = include && item.AppointmentSet;
        } else {
          include = include && !item.AppointmentSet;
        }
      }
      return include;
    });
  },[data,carrierFilter,statusFilter,stateFilter,appointmentFilter]);

  const lessThanFiveAttempts = useMemo(() => {
    let count = 0;
    inspectionsInTable?.forEach(item => {
      if (item.HistoryCount < 5) {
        count++;
      }
    });
    return count;
  },[inspectionsInTable]);

  const moreThanFiveAttempts = useMemo(() => {
    let count = 0;
    inspectionsInTable?.forEach(item => {
      if (item.HistoryCount >= 5) {
        count++;
      }
    });
    return count;
  },[inspectionsInTable]);

  const appointmentSet = useMemo(() => {
    let count = 0;
    inspectionsInTable?.forEach(item => {
      if (item.AppointmentSet) {
        count++;
      }
    });
    return count;
  },[inspectionsInTable]);

  const getAttemptsDisplay = attempts => {
    if (!attempts) {
      return "--";
    }

    const percentage = (attempts / inspectionsInTable?.length * 100).toFixed(1);
    return `${attempts} (${percentage}%)`;
  };


  const allCarriers = useMemo(() => {
    if (!data) {
      return null;
    }
    let carriers = [];
    data?.forEach(item => {
      if (!carriers.includes(item.Carrier)) {
        carriers.push(item.Carrier);
      }
    });
    carriers.sort();
    return carriers;
  },[data]);

  const allStatuses = useMemo(() => {
    if (!data) {
      return null;
    }
    let statuses = [];
    data?.forEach(item => {
      if (!statuses.includes(item.Status)) {
        statuses.push(item.Status);
      }
    });
    statuses.sort();
    return statuses;
  },[data]);

  const allStates = useMemo(() => {
    if (!data) {
      return null;
    }
    let states = [];
    data?.forEach(item => {
      if (!states.includes(item.State)) {
        states.push(item.State);
      }
    });
    states.sort();
    return states;
  },[data]);

  const addNewNote = () => {
    postNewNote(inspectionSelected.PolicyNo, newNote ).then(r => {
      if (r.status < 399) {
        toast.success("Note added", {
          autoClose: 2000
        });
        setInfoDialogOpen(false);
        mutate();
        // add note locally
      } else {
        console.log("Error adding note - " + r.data.message);
        toast.error('Unable to add note - please try again later');
      }
    });

  };

  return (
    <>

      <div className={"lg:tw-flex tw-gap-16 tw-justify-between mt-4"}>
        <div className={"lg:tw-flex tw-gap-16 tw-items-center"}>
          <div>
            <p>Filter by Carrier</p>
            <select className={"tw-rounded-md"} onChange={e => setCarrierFilter(e.target.value)}>
              <option>All</option>
              {allCarriers?.map(carrier => <option key={carrier} value={carrier}>{carrier}  ({data?.filter(item => item.Carrier === carrier).length})</option>)}
            </select>
          </div>
          <div>
            <p>Filter by Status</p>
            <select className={"tw-rounded-md"} onChange={e => setStatusFilter(e.target.value)}>
              <option>All</option>
              {allStatuses?.map(status => <option key={status} value={status}>{status} ({data?.filter(item => item.Status === status).length})</option>)}
            </select>
          </div>
          <div>
            <p>Filter by State</p>
            <select className={"tw-rounded-md"} onChange={e => setStateFilter(e.target.value)}>
              <option>All</option>
              {allStates?.map(state => <option key={state} value={state}>{state} ({data?.filter(item => item.State === state).length}) </option>)}
            </select>
          </div>
          <div>
            <p>Appointment Set</p>
            <select className={"tw-rounded-md"} onChange={e => setAppointmentFilter(e.target.value)}>
              <option key={"All"}>All</option>
              {["Yes","No"].map(appointment => <option key={appointment} value={appointment}>{appointment} ({data?.filter(item => item.AppointmentSet === (appointment==="Yes")).length})</option>)}
            </select>
          </div>
        </div>
        <div className={"tw-w-64"}><DashboardCard title={"Appointment Set"} value={getAttemptsDisplay(appointmentSet)}/></div>
      </div>

      <div className={"tw-grid tw-grid-cols-3 tw-gap-4 md:tw-gap-8 xl:tw-gap-16 tw-my-6"}>
        {showAttempts && <DashboardCard title={"Less than 5 attempts"} value={getAttemptsDisplay(lessThanFiveAttempts)}/>}
        {showAttempts && <DashboardCard title={"5 attempts or more"} value={getAttemptsDisplay(moreThanFiveAttempts)}/>}
      </div>

      {data ?
        <div className={"tw-mt-8"}>
          <h3>{inspectionsInTable?.length} {inspectionsInTable?.length===1 ? "Inspection" : "Inspections"} {`${isFilterSet ? `(filtered from ${data.length})`: ""}`}</h3>
          <Table columns={columns} data={inspectionsInTable || []} initialSort={initialSort}/>
        </div>
        :
        <LoadingAnimations/>
      }
      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)} maxWidth='xl' fullWidth={true}>
        <DialogContent>
          {/*<div*/}
          {/*  className={"tw-font-bold tw-text-lg lg:tw-text-2xl tw-mb-6"}>{inspectionSelected.PolicyNo} - {inspectionSelected.Address} - {inspectionSelected.Insured}</div>*/}

          {inspectionSelected && <InspectionPreContact policyNo={inspectionSelected.PolicyNo}/>}

          {/*<div className={"tw-text-xs"}>*/}
          {/*  <InspectionHistoryTable items={inspectionHistory}/>*/}
          {/*</div>*/}

          {/*<TextareaAutosize autoFocus className="tw-w-full tw-mt-6" rowsMin={6} placeholder="Notes"*/}
          {/*  onChange={(e) => {*/}
          {/*    setNewNote(e.target.value);*/}
          {/*  }}/>*/}

          {/*<DialogActions className='sm:tw-mt-4'>*/}
          {/*  <Button variant={"contained"} color={"primary"} startIcon={<SaveRounded/>} onClick={addNewNote}>*/}
          {/*                  Add Note*/}
          {/*  </Button>*/}
          {/*  <Button variant={"contained"} onClick={() => setInfoDialogOpen(false)} color={"secondary"}*/}
          {/*    startIcon={<CancelRounded/>}>*/}
          {/*                  Cancel*/}
          {/*  </Button>*/}
          {/*</DialogActions>*/}

        </DialogContent>
      </Dialog>

    </>
  );

}