import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import COMPONENTS from "./components";
import { config } from "./core";
// Style
import "./styles/index.css";
import { createTheme, ThemeProvider } from "@material-ui/core";
import ErrorBoundary from "./ErrorBoundary.jsx";
import axios from "axios";
import { SWRConfig } from 'swr';

// CONFIG
const data = window.reactData;
config.api.version = data.apiVersion;
config.api.jwt = data.jwt;
config.routeParams = data.routeParams;
config.user = data.user;
config.api.baseUrl = "/api/" + config.api.version;
config.externalAdmin = data.externalAdmin;

// Get the component containers
var containers = document.querySelectorAll("*[data-component]");

const theme = createTheme({
  palette: {
    primary: {
      main: "#00acdc",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#005088",
      contrastText: "#ffffff",
    },
    error: {
      main: "#d71433",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#d71433",
      contrastText: "#ffffff",
    },
    success: {
      main: "#94ba33",
      contrastText: "#ffffff",
    },
  },
  breakpoints: { // match tailwind breakpoints
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    },
  },
  // fontFamily: font
});

for (let container of containers) {
  // Get the component type name from an attribute
  const type = container.getAttribute("data-component");

  const RootComponent = COMPONENTS[type];

  const fetcher = (...args) => axios.get(...args).then((res) => res.data);

  let defaultRefreshInterval = process.env.NODE_ENV === 'production' ? 10000 : 5000;

  const BootstrapComponent =
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <SWRConfig
            value={{
              refreshInterval: defaultRefreshInterval,
              fetcher: fetcher
            }}
          >
            <Suspense fallback="loading">
              <RootComponent/>
            </Suspense>
          </SWRConfig>
        </ThemeProvider>
      </ErrorBoundary>
  ;
  ReactDOM.render(BootstrapComponent, container);
}
