import React from "react";
// import PropTypes from 'prop-types'
import { useTable } from "react-table";

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  return (
    <div className="tw-shadow-sm tw-border-b tw-border-gray-200 sm:tw-rounded-lg">
      <table
        className="tw-min-w-full tw-divide-y tw-divide-gray-200"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, i) =>
            <tr key={`${i}`} className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) =>
                <th key={`${i}-${j}`} width={column.minWidth>0 ? column.minWidth : null}
                  className="tw-px-6 tw-py-3  tw-text-left
                  tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider tw-font-bold"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              )}
            </tr>
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={`${i}`}{...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  let classnames = "tw-px-6 tw-py-2 ";
                  i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                  return (
                    <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// Table.propTypes = {

// }

export default Table;
