import React, { useState } from "react";
import { Checkbox, Grid, FormControl, FormLabel, FormGroup, Paper, TextField, FormControlLabel, Button } from "@material-ui/core";

const ProfileAttachmentsTrainingCard = () => {
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <h2 className="tw-border-b-2 tw-pb-4 tw-font-black">Attachments</h2>
          <p className="tw-font-black tw-text-lg">Check and Upload Files</p>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Drivers license or ID" />
              <FormControlLabel control={<Checkbox />} label="Inspector license" />
              <FormControlLabel control={<Checkbox />} label="NDA" />
              <FormControlLabel control={<Checkbox />} label="W-4" />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={6}>
          <h2 className="tw-border-b-2 tw-pb-4 tw-font-black">Training</h2>
          <div className="">
            <Button className="btn tw-rounded-xl tw-font-black">Available</Button>
            <Button variant="outlined" className="btn tw-rounded-xl tw-font-black">Completed</Button>
            <Button variant="outlined" className="btn tw-rounded-xl tw-font-black">In Progress</Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileAttachmentsTrainingCard;
