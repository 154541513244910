export function colorToRGBA(color) {
  // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
  // color must be a valid canvas fillStyle. This will cover most anything
  // you'd want to use.
  // Examples:
  // colorToRGBA('red')  # [255, 0, 0, 255]
  // colorToRGBA('#f00') # [255, 0, 0, 255]
  var cvs, ctx;
  cvs = document.createElement('canvas');
  cvs.height = 1;
  cvs.width = 1;
  ctx = cvs.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);
  return ctx.getImageData(0, 0, 1, 1).data;
}

export function byteToHex(num) {
  // Turns a number (0-255) into a 2-character hex number (00-ff)
  return ('0'+num.toString(16)).slice(-2);
}

export function colorToHex(color) {
  // Convert any CSS color to a hex representation
  // Examples:
  // colorToHex('red')            # '#ff0000'
  // colorToHex('rgb(255, 0, 0)') # '#ff0000'
  var rgba, hex;
  rgba = colorToRGBA(color);
  hex = [0,1,2].map(
    function(idx) { return byteToHex(rgba[idx]); }
  ).join('');
  return "#"+hex;
}

export const getTextColorForBackground = backgroundColor => {
  if (!backgroundColor.startsWith("#")) {
    backgroundColor = colorToHex(backgroundColor);
  }
  const hex = backgroundColor.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 150 ? "black" : "white";
};

export const encodeSVG = rawSvgString => {
  const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;

  // Use single quotes instead of double to avoid URI encoding
  rawSvgString = rawSvgString
    .replace(/'/g, '"')
    .replace(/>\s+</g, '><')
    .replace(/\s{2,}/g, ' ');

  return (
    'data:image/svg+xml;utf-8,' +
      rawSvgString.replace(symbols, encodeURIComponent)
  );
};