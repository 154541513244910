import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, InputLabel, makeStyles, MenuItem,
  Modal, Select,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { CancelRounded, Save, SaveRounded } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

function TheDialog({ open, editLabel, saveHandler, closeHandler, inspection, children }) {
  return (
    <Dialog open={open !== null} onClose={closeHandler} maxWidth='sm' fullWidth={true}>

      <DialogContent>
        <div className={"tw-mb-4"}>
          <p className={"tw-text-black tw-font-bold tw-text-lg"}>{editLabel}</p>
          <p className={"tw-text-gray-700"}>{inspection.PolicyNo} - {inspection.Name}</p>
        </div>
       
        {children}

        <DialogActions className='sm:tw-mt-4'>
          <Button variant={"contained"} onClick={saveHandler} color={"primary"}
            startIcon={<SaveRounded/>}>
                        Save
          </Button>
          <Button variant={"contained"} onClick={closeHandler} color={"secondary"}
            startIcon={<CancelRounded/>}>
                        Cancel
          </Button>
        </DialogActions>

      </DialogContent>


    </Dialog>
  );
}


export default function InspectionPreEditModal({ open, closeHandler, editingType, saveHandler, inspection }) {

  const classes = useStyles();

  const [genericItem, setGenericItem] = useState("");
  const [address, setAddress] = useState({});
  const [appointment, setAppointment] = useState({});
  const [editLabel, setEditLabel] = useState("");

  const formatAppointmentDate = date => {
    const jsDate = new Date(date);
    return jsDate.toISOString();
  };

  const saveClicked = () => {
    switch (editingType) {
    case "notes":
      inspection.Notes = genericItem;
      saveHandler({ notes: genericItem });
      break;
    case "phone":
      inspection.Telephone = genericItem;
      saveHandler({ phone: genericItem });
      break;
    case "email":
      inspection.Email = genericItem;
      saveHandler({ email: genericItem });
      break;
    case "address":
      inspection.Address = address.address;
      inspection.City = address.city;
      inspection.County = address.county;
      inspection.State = address.state;
      inspection.Zipcode = address.zipcode;
      saveHandler({ address: address.address, city: address.city, county: address.county, state: address.state, zipcode: address.zipcode });
      break;
    case "appointment":
      console.log({ appointment });
      if (appointment.date === 0 || appointment.time === 0) {
        return;
      }
      inspection.AppointmentDate = appointment.date;
      inspection.AppointmentTime = appointment.time;
      saveHandler({ appointmentDate: formatAppointmentDate(appointment.date), appointmentTime: appointment.time });
    }
  };

  const initFields = () => {
    switch (editingType) {
    case "notes":
      setGenericItem(inspection.Notes);
      setEditLabel("Private Notes");
      break;
    case "phone":
      setGenericItem(inspection.Telephone);
      setEditLabel("Telephone");
      break;
    case "email":
      setGenericItem(inspection.Email);
      setEditLabel("Email");
      break;
    case "address":
      setEditLabel("Address");
      setAddress({ address: inspection.Address, city: inspection.City, county: inspection.County, state: inspection.State, zipcode: inspection.Zipcode });
      break;
    case "appointment":
      setEditLabel("Appointment");
      setAppointment({ date: 0, time: 0 });
      break;
    }

  };

  useEffect(() => {
    initFields();
  }, [editingType, inspection]);

  const getEditForm = () => {
    switch (editingType) {
    case "address":
      return (
        <>
          <TextField className={classes.root} fullWidth label="Address" value={address.address} onChange={(e) => setAddress(prevState => ({
            ...prevState,
            address: e.target.value
          }))}/>
          <TextField className={classes.root} fullWidth label="City" value={address.city} onChange={(e) => setAddress(prevState => ({
            ...prevState,
            city: e.target.value
          }))}/>
          <TextField className={classes.root} fullWidth label="County" value={address.county} onChange={(e) => setAddress(prevState => ({
            ...prevState,
            county: e.target.value
          }))}/>
          <TextField className={classes.root} label="State" value={address.state} onChange={(e) => setAddress(prevState => ({
            ...prevState,
            state: e.target.value
          }))}/>
          <TextField className={classes.root} label="Zip Code" value={address.zipcode} onChange={(e) => setAddress(prevState => ({
            ...prevState,
            zipcode: e.target.value
          }))}/>
        </>
      );
    case "appointment":
      return (
        <>
          <p>Appointment Date</p>
          <TextField fullWidth className={classes.root} type="date" value={appointment.date} onChange={(e) => setAppointment(prevState => ({
            ...prevState,
            date: e.target.value
          }))}/>
          <p className="tw-mt-4">Appointment Time</p>
          <FormControl fullWidth className={classes.root}>
            <Select
              value={appointment.time}
              onChange={(e) => setAppointment(prevState => ({
                ...prevState,
                time: e.target.value
              }))}
            >
              <MenuItem value={0}>--</MenuItem>
              <MenuItem value={1}>AM</MenuItem>
              <MenuItem value={2}>Midday</MenuItem>
              <MenuItem value={3}>PM</MenuItem>
            </Select>
          </FormControl>
        </>
      );
    case "notes":
      return (
        <TextareaAutosize autoFocus className="tw-w-full" rowsMin={6} placeholder="" value={genericItem}
          onChange={(e) => setGenericItem(e.target.value)}/>
      );
    default:
      return (
        <TextField className={classes.root} fullWidth label="" value={genericItem} onChange={(e) => setGenericItem(e.target.value)}/>
      );

    }
  };


  return (
    <>
      <TheDialog open={editingType} editLabel={editLabel} closeHandler={closeHandler} saveHandler={saveClicked}
        inspection={inspection}>
        {getEditForm()}
      </TheDialog>
    </>
  );
}
