import React, { useEffect, useState } from 'react';
import Table from "./Table";
import LoadingAnimations from "../Loaders/Skeleton";
import { dateTimeToString } from "../../utilities/dateTime";
import FSButton from "../Buttons/FSButton";
import { Cancel } from "@material-ui/icons";
import ConfirmDialog from "../Modal/ConfirmDialog";
import { inspections } from "../../api/inspections";

const columns = [
  { Header: "Name", accessor: "original_filename" },
  { Header: "Uploaded", accessor: "created", minWidth: 200 },
  { Header: "", accessor: "download", minWidth: 150 },
  { Header: "", accessor: "delete", minWidth: 150 },
];


export default function InspectionDocumentsTable({ policyNo, items, mutateInspection }) {

  const [data, setData] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deletingDocument, setDeletingDocument] = useState(null);

  const downloadDocument = (id,originalFilename) => {
    window.open(`/inspections/${policyNo}/downloaddocument/${id}/${originalFilename}`);
  };

  const deleteDocument = (id, original_filename) => {
    setConfirmDeleteOpen(true);
    setDeletingDocument({ id,original_filename });
  };

  const doDeleteFile = async () => {
    setConfirmDeleteOpen(false);
    setDeletingDocument(null);

    const res = await inspections.deleteDocument(policyNo, deletingDocument.id);
    if (res.status !== 204) {
      alert("Unable to delete document, please try again later");
      return;
    }
    mutateInspection();
  };

  useEffect(() => {
    if (items === null) {
      setData(null);
      return;
    }

    // Append Download and Delete buttons
    const newItems = items.map((item) => {
      item.created = dateTimeToString(new Date(item.created));
      item.download = <FSButton className={"tw-w-full sm:tw-w-auto"} color="primary" onClick={() => downloadDocument(item.id,item.original_filename)}>Download</FSButton>;
      item.delete = <FSButton className={"tw-w-full sm:tw-w-auto"} color="warning" onClick={() => deleteDocument(item.id,item.original_filename)}>Delete</FSButton>;
      return item;
    });
    setData(newItems);
  }, [items]);


  if (data === null) {
    return <p>No documents yet</p>;
  }

  return (
    <div>
      <Table columns={columns} data={data}/>

      <ConfirmDialog title="Delete document?" open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen} onConfirm={() => doDeleteFile()}>
        Delete document <strong>{deletingDocument?.original_filename}</strong>?
      </ConfirmDialog>

    </div>
  );
}