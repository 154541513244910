const formatMoney = (value, format = 'us-US', currency = 'USD', digits = '0', style = 'currency') => {

  const formatter = new Intl.NumberFormat(format || 'en-US', {
    style: style,
    currency: currency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: digits
  });

  return formatter.format(value);
};


export default formatMoney;