import { ApiCore } from "./core";

const url = "inspectors";

const apiInspectors = new ApiCore({
  list: false,
  get: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

export { apiInspectors };
