import React, { useEffect, useState } from 'react';
import useSWR from "swr";
import { BASE_URL } from "../api/core/provider";
import Table from "../components/tables/Table";
import LoadingAnimations from "../components/Loaders/Skeleton";
import { Button } from "@material-ui/core";
import { dateToString } from "../utilities/dateTime";

export default function MissingPhotos() {

  const { data } = useSWR(`${BASE_URL()}/inspections/missingphotos?days=7`, { refreshInterval: 0 });

  const cols = [
    { Header: "Company", accessor: "Company.Name" },
    { Header: "Policy No", accessor: "PolicyNo" },
    { Header: "Sections", accessor: "Message" },
    { Header: "Updated", accessor: "updated" },
    { Header: "Status", accessor: "Status" },
    { Header: "", accessor: "view" },
  ];

  const [inspections, setInspections] = useState(null);

  const viewInspection = inspection => {
    window.open(`/missingphotos/view/${inspection.Company.Id}/${inspection.PolicyNo}`, "_blank");
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.result) {
      setInspections([]);
      return;
    }

    setInspections(data.result.map(inspection => {
      inspection.view =
                    <button className="btn btn-primary" onClick={() => viewInspection(inspection)}>View</button>;
      inspection.updated = dateToString(new Date(inspection.UpdatedAt.Time));
      return inspection;
    }
    ));

  }, [data]);

  return (
    <div>
      <h2>Missing Photos</h2>
      {inspections ?
        <>
          <Table data={inspections} columns={cols}/>
          {inspections.length === 0 && <p className={"tw-text-2xl tw-mt-8 tw-text-center"}>None 🎉🥳🎉</p>}
        </>
        :
        <LoadingAnimations width={'100%'} height={30} count={10}/>
      }
    </div>
  );

}