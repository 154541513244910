import axios from "axios";
import { handleResponse, handleError } from "./response";
import { config } from "~/core";

export const BASE_URL = () => "/api/" + config.api.version;

// We want all statuses to return a consistent response from the JS API layer
// A 400 is still a valid response and should be handled accordingly by the UI
// (maybe we do some model validation server side)
axios.defaults.validateStatus = function () {
  return true;
};

axios.interceptors.request.use(
  (cfg) => {
    cfg.headers = {
      Authorization: `Bearer ${config.api.jwt}`,
      Accept: "application/json",
      "X-Company-ID": config.user.company,
    };
    return cfg;
  },
  (error) => {
    console.error(error);

    return Promise.reject(error);
  }
);

/** @param {string} resource */
const list = (resource, params) => {
  return axios
    .get(`${BASE_URL()}/${resource}`, { params })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const get = (resource, id) => {
  let url = `${BASE_URL()}/${resource}`;

  // Explicit conditional here. Don't want to lose truthy values like 0 & "" etc
  if (id !== undefined && id !== null) {
    url += "/" + id;
  }

  return axios.get(url).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const downloadPost = (resource, data) => {
  return axios({
    url: `${BASE_URL()}/${resource}`,
    method: "POST",
    responseType: "blob",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {
  return axios
    .post(`${BASE_URL()}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model) => {
  return axios
    .put(`${BASE_URL()}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource, model) => {
  return axios
    .patch(`${BASE_URL()}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id) => {
  return axios
    .delete(`${BASE_URL()}/${resource}/${id}`)
    .then(handleResponse)
    .catch(handleError);
};

export const apiProvider = {
  list,
  get,
  post,
  put,
  patch,
  delete: remove,
  downloadPost,
};

export const getJWTClaims = () => {
  let parts = config.api.jwt.split(".");
  let payload = parts[1];
  return JSON.parse(atob(payload));
};

export const getRoles = () => {
  let claims = getJWTClaims();
  return claims.roles;
};