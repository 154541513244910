import { ApiCore } from "./core";

const url = "profile";

const apiUsers = new ApiCore({
  list: false,
  get: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

export { apiUsers };
