import React, { useEffect, useMemo, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { config } from "../core";
import { Button } from "@material-ui/core";
import useSWR from "swr";
import produce from "immer";
import { DateTime } from "luxon";
import Table from "../components/tables/Table";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiY29saW5uaWNoIiwiYSI6ImNrbHMxM2RoMzA4Y3cydnB2cTlncnNuN2kifQ.31ld9j_I5ILs2QaG5-Dalg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const companies = [
  { name:"FSS", id:2, selected:true },
  { name:"W", id:11, selected:false },
  { name:"JDUB", id:5, selected:false },
  { name:"Ironshore", id:12, selected:false },
];

const inspectionTypes = [
  { type:"B", selected:true },
  { type:"B+", selected:true },
  { type:"B++", selected:true },
  { type:"A", selected:true },
  { type:"BR", selected:true },
  { type:"EF", selected:true },
  { type:"P", selected:true },
  { type:"I", selected:true },
  { type:"V", selected:true },
  { type:"E", selected:true },
];

export default function AreaMap() {

  const columns = [
    { Header: "State", accessor: "state", minWidth: 140 },
    { Header: "Count", accessor: "count", minWidth: 170 },
  ];

  const inspectionsMapContainer = useRef();

  const [fromDate, setFromDate] = useState(DateTime.now().minus({ months:12 }).startOf('day').toFormat("yyyy-MM-dd"));
  const [toDate, setToDate] = useState(DateTime.now().toFormat("yyyy-MM-dd"));
  const [companyFilter, setCompanyFilter] = useState(companies);
  const [typeFilters, setTypeFilters] = useState(inspectionTypes);
  const [markersState, setMarkersState] = useState(null);
  const [map, setMap] = useState(null);
  const [data, setData] = useState(null);
  const [zoomedMap, setZoomedMap] = useState(false);

  useEffect(() => {
    const m = new mapboxgl.Map({
      container: inspectionsMapContainer.current,
      style: 'mapbox://styles/colinnich/ckls14y0q119r17qpv4o5u3od',
      center: [-89.8, 29.4],
      zoom: 5.2
    });
    m.addControl(new mapboxgl.NavigationControl());

    m.on('zoomend', () => {
      if (m.zoom > 9) {
        setZoomedMap(true);
      } else {
        setZoomedMap(false);
      }
    });

    setMap(m);
  }, []);

  const toggleCompany = id => {
    setCompanyFilter(produce((draft) => {
      console.log("Toggling ",id);
      for (let i=0;i<draft.length;i++) {
        if (id === draft[i].id) {
          draft[i].selected = !draft[i].selected;
          break;
        }
      }
    }));
  };

  const toggleType = type => {
    setTypeFilters(produce((draft) => {
      console.log("Toggling ",type);
      for (let i=0;i<draft.length;i++) {
        if (type === draft[i].type) {
          draft[i].selected = !draft[i].selected;
          break;
        }
      }
    }));
  };

  const isCompanySelected = id => {
    for (let i=0;i<companyFilter.length;i++) {
      if (id === companyFilter[i].id) {
        return companyFilter[i].selected;
      }
    }
  };

  const isTypeSelected = type => {
    for (let i=0;i<typeFilters.length;i++) {
      if (type === typeFilters[i].type) {
        return typeFilters[i].selected;
      }
    }
  };

  const dataLoaded = (data, key, config) => {
    if (!data) {
      return;
    }

    setData(data);

    if (markersState) {
      markersState.forEach(marker => {
        marker.remove();
      });
    }

    let markers = [];

    if (!data.result) {
      return;
    }
    for (let i = 0; i < data.result.length; i++) {
      let zipcode = data.result[i];
      if (zipcode.Lat !== 0 && zipcode.Long !== 0) {
        const el = document.createElement('div');
        let colour = "";
        if (zipcode.Count < 10) {
          colour = "tw-bg-green-600";
        } else if (zipcode.Count < 100) {
          colour = "tw-bg-yellow-600";
        } else if (zipcode.Count < 1000) {
          colour = "tw-bg-orange-600";
        } else {
          colour = "tw-bg-red-600";
        }
        el.className = `tw-w-2 tw-h-2 tw-rounded-full tw-cursor-pointer ${colour}`;
        markers.push(new mapboxgl.Marker(el)
          .setLngLat([zipcode.Long, zipcode.Lat])
          .setPopup(new mapboxgl.Popup().setHTML(`
            <div class='tw-p-2'>
              <p class='tw-text-lg tw-font-bold tw-text-secondary'>
                ${zipcode.ZipCode}
              </p>
              <p class='tw-text-sm'>
                ${zipcode.County}, ${zipcode.StateCode}
                <br>
                ${zipcode.Count} policies
              </p>
            </div>
          `))
        // .setPopup(new mapboxgl.Popup().setHTML("" +  + "</p></div>")) // add popup
          .addTo(map));
        setMarkersState(markers);
      }
    }
  };

  useSWR(`${config.api.baseUrl}/reports/zipcodecounts?from=${fromDate}&to=${toDate}&companies=${companyFilter.filter(company => {return company.selected;}).map(company => {return company.id;}).join(",")}&types=${typeFilters.filter(type => {return type.selected;}).map(type => {return encodeURIComponent(type.type);}).join(",")}`, {
    onSuccess: dataLoaded,
    refreshInterval: 0,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const tableData = useMemo(() => {

    if (!data) {
      return [];
    }

    let stateMap = new Map();

    data.result.forEach(zipCode => {
      if (stateMap.get(zipCode.StateCode)) {
        stateMap.set(zipCode.StateCode,stateMap.get(zipCode.StateCode) + zipCode.Count);
      } else {
        stateMap.set(zipCode.StateCode,zipCode.Count);
      }
    });

    let total = 0;
    let res = [];

    stateMap.forEach((value, key) => {
      console.log(value,":",key);
      res.push({ state: key, count : value });
      total += value;
    });

    res.sort((a, b) => {
      return a.count < b.count;
    });

    res.push({ state:"Total",count:total });

    return res;


  },[data]);

  return (
    <>
      <h1>Area Map</h1>
      <div className={"tw-my-6 tw-flex tw-justify-between"}>
        <div>
          <h4 className={""}>Company</h4>
          <div className={"tw-flex tw-gap-8 tw-items-baseline"}>
            {companyFilter.map(company =>
              <Button color={"primary"} key={company.id} variant={isCompanySelected(company.id) ? 'contained' : 'outlined'} onClick={() => toggleCompany(company.id)}>{company.name}</Button>
            )}
          </div>
        </div>
        <div>
          <h4>Time Period</h4>
          <div className={"tw-flex tw-gap-8 tw-items-baseline"}>
            <div className={"tw-flex tw-gap-4 tw-items-baseline"}>
              <label htmlFor={"fromDate"}>From</label>
              <input id={"fromDate"} type={"date"} value={fromDate} onChange={(e) => setFromDate(e.target.value)}/>
            </div>
            <div className={"tw-flex tw-gap-4 tw-items-baseline"}>
              <label htmlFor={"toDate"}>To</label>
              <input id={"toDate"} type={"date"} value={toDate} onChange={(e) => setToDate(e.target.value)}/>
            </div>
            {/*<Button variant={dateFilter === 3650 ? 'contained' : 'outlined'} onClick={() => setDateFilter(3650)}>All Time</Button>*/}
            {/*<Button variant={dateFilter === 730 ? 'contained' : 'outlined'} onClick={() => setDateFilter(730)}>Last*/}
            {/*            2 years</Button>*/}
            {/*<Button variant={dateFilter === 365 ? 'contained' : 'outlined'} onClick={() => setDateFilter(365)}>Last*/}
            {/*            year</Button>*/}
            {/*<Button variant={dateFilter === 90 ? 'contained' : 'outlined'} onClick={() => setDateFilter(90)}>Last 3*/}
            {/*            months</Button>*/}
            {/*<Button variant={dateFilter === 30 ? 'contained' : 'outlined'} onClick={() => setDateFilter(30)}>Last*/}
            {/*            month</Button>*/}
          </div>
        </div>
      </div>
      <div className={"tw-mb-6"}>
        <h4>Inspection Type</h4>
        <div className={"tw-flex tw-justify-start tw-gap-8"}>
          {inspectionTypes.map(inspectionType =>
            <Button color={"primary"} key={inspectionType.type} variant={isTypeSelected(inspectionType.type) ? 'contained' : 'outlined'} onClick={() => toggleType(inspectionType.type)}>{inspectionType.type}</Button>
          )}

        </div>
      </div>
      <div style={{ height: '800px' }}>
        <div className="map-container tw-h-full" ref={inspectionsMapContainer}/>
      </div>

      <div className={"tw-my-8"}>
        <Table columns={columns} data={tableData}/>
      </div>

    </>
  );

}