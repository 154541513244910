export const dateTimeToString = date => {
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: "America/New_York"
  });
};

export const dateToString = date => {
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: "UTC"
  });
};

export const formatTime = date => {
  return date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: "America/New_York"
  });
};

export const secondsToDate = timeInSeconds => {
  let date = new Date(null);
  date.setSeconds(timeInSeconds+18000);
  return date;
};

export const parseDateTimeAndChangeToLocalTimezone = (dateString) => {
  let date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};