/* eslint-disable react/display-name */

import React, { useEffect, useMemo, useState } from 'react';
import useSWR from "swr";
import { config } from "../core";
import 'chartjs-plugin-colorschemes';
import { useSortBy, useTable } from "react-table";
import { DateTime } from "luxon";
import { CircularProgress } from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';
import DashboardCard from "../components/cards/DashboardCard";


const red = '#d71433';
const yellow = '#F8ED00';
const amber = 'rgba(251,191,36)';
const green = '#94ba33';
const black = '#000000';

const livePieOptions = {
  legend: {
    display: false,
    position: 'left',
  }
};

const MonthlyPieOptions = {
  legend: {
    display: true,
    position: 'left',
  }
};


const inspectorPieOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
};

const line = <p className={"tw-border-secondary tw-bg-secondary tw-py-1 tw-border-0.5 tw-rounded-lg tw-mb-4"}/>;

const Table = ({ data }) => {

  const columns = useMemo(() => [
    { Header: "Inspector", accessor: "Inspector" },
    { Header: "Assigned", accessor: "Assigned" },
    { Header: "Submitted", accessor: "Submitted" },
    { Header: "Turnover", accessor: "Turnover" ,
      Cell: props => `${props.row.original.Turnover.toFixed(1)}%`
    },
    { Header: "Late", accessor: "Late" ,
      Cell: props => `${props.row.original.Late} (${props.row.original.LatePercent.toFixed(1)}%)`
    },
    { Header: "", accessor: "chart",
      Cell: props => {
        const onTime = (Number(props.row.original.Submitted) || 0) - (Number(props.row.original.Late) || 0);
        const late = Number(props.row.original.Late) || 0;
        return <div className={"tw-w-20 tw-h-14"}><Pie
          data={{
            datasets: [{
              data: [onTime,late],
              backgroundColor: [
                green,red
              ]
            }],
            labels: ["On Time", "Late"]
          }}
          options={inspectorPieOptions}
        /></div>;
      }
    },
  ],[]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns, data, initialState: {
      sortBy: [
        {
          id: "Inspector",
          desc: false
        }
      ]
    },
  }, useSortBy);
  return (
    <table
      className="tw-mt-10 tw-min-w-full tw-divide-y tw-divide-gray-200"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup, i) =>
          <tr key={`${i}`}
            className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) =>
              <th key={`${i}-${j}`} width={column.minWidth > 0 ? column.minWidth : null}
                className={`tw-px-6 tw-py-3 tw-text-left tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider tw-font-bold 
                      ${column.isSorted ? column.isSortedDesc ? `sort-desc` : `sort-asc` : ``}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")} {
                  column.isSorted
                    ? column.isSortedDesc
                      ?
                      <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-down"/>
                      </div>
                      : <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-up"/>
                      </div>
                    : ''
                }
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={`${i}`}{...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                let classnames = "tw-px-6 tw-py-2 ";
                i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                return (
                  <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};


export default function LateReport() {

  const [dataLoading, setDataLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(`${DateTime.now().year}-${DateTime.now().month}`);

  // useEffect(() => {
  //   if (window.location.hash.length > 0) {
  //     console.log(window.location.hash.substr(1));
  //     const params = new URLSearchParams(window.location.hash.substr(1));
  //     console.log(params.get("period"));
  //     setSelectedMonth(params.get("period"));
  //   }
  // },[window.location.hash]);
  //
  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   if (selectedMonth) {
  //     params.append("period", selectedMonth);
  //   } else {
  //     params.delete("period");
  //   }
  //   window.location.hash = params.toString();
  // }, [selectedMonth, history]);

  const { data: liveData } = useSWR(`${config.api.baseUrl}/reports/inspectionlates/live`, {
    refreshInterval: 30000,
  });
  const { data: timeData } = useSWR(`${config.api.baseUrl}/reports/inspectionlates/period?month=${selectedMonth}`, {
    refreshInterval: 30000,
  });
  const { data: lastThreeMonths } = useSWR(`${config.api.baseUrl}/reports/inspectionlates/last3months`, {
    refreshInterval: 0,
  });

  useEffect(() => {
    if (timeData) {
      setDataLoading(false);
    }
  }, [timeData]);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const monthSelections = useMemo(() => {
    let res = [];
    const currentMonth = DateTime.now().startOf('month');
    for (let i=0;i<13;i++) {
      const thisMonth = currentMonth.minus({ months: i });
      res.push({ id: `${thisMonth.year}-${thisMonth.month}`, display: `${thisMonth.monthShort} ${thisMonth.year}` });
    }

    return res;
  },[]);

  function changeMonth(value) {
    setDataLoading(true);
    setSelectedMonth(value);
  }

  const liveDataCalc = useMemo(() => {
    if (!liveData) {
      return null;
    }

    const late = Number(liveData.result.Late) || 0;
    const due = Number(liveData.result.ComingDue) || 0;
    const ok = liveData.result.Open - due - late;

    const total = late+due+ok;

    const okPercent = ok/total*100;
    const duePercent = due/total*100;
    const latePercent = late/total*100;

    return { ok, due, late, okPercent, duePercent, latePercent };
  }, [liveData]);

  const liveDataCategoryCalc = useMemo(() => {
    if (!liveData) {
      return null;
    }

    const late30 = liveData.result.LateCategories.Late1 || 0;
    const late45 = liveData.result.LateCategories.Late2 || 0;
    const late180 = liveData.result.LateCategories.Late3 || 0;
    const excluded = liveData.result.Excluded || 0;

    const total = late30+late45+late180+excluded;

    const late30Percent = late30/total*100;
    const late45Percent = late45/total*100;
    const late180Percent = late180/total*100;
    const excludedPercent = excluded/total*100;

    return { late30, late45, late180, excluded, late30Percent, late45Percent, late180Percent, excludedPercent };

  },[liveDataCalc]);

  const livePieData = useMemo(() => {
    if (!liveDataCalc) {
      return null;
    }
    return {
      datasets: [{
        data: [liveDataCalc.ok,liveDataCalc.due,liveDataCalc.late],
        backgroundColor: [
          green,amber,red
        ]
      }],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        "OK","Coming Due","Late"
      ]
    };

  }, [liveDataCalc]);

  const lateCategoryPieData = useMemo(() => {
    if (!liveDataCategoryCalc) {
      return null;
    }

    return {
      datasets: [{
        data: [liveDataCategoryCalc.late30,liveDataCategoryCalc.late45, liveDataCategoryCalc.late180, liveDataCategoryCalc.excluded],
        backgroundColor: [
          yellow,amber,red,black
        ]
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        "Late > 30 days","Late > 45 days","Late > 180 days","Excluded Lates"
      ]
    };

  },[liveDataCategoryCalc]);

  const timePieData = useMemo(() => {
    if (!timeData) {
      return null;
    }
    const onTime = (Number(timeData.result.Summary.Submitted) || 0) + (Number(timeData.result.Summary.Late) || 0);
    const late = Number(timeData.result.Summary.Late) || 0;

    return {
      datasets: [{
        data: [onTime,late],
        backgroundColor: [
          green,red
        ]
      }],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        "On time","Late"
      ]
    };

  },[timeData]);

  const get3MonthSummaryData = ({ Created, Submitted }) => {
    return <div className={"tw-grid tw-grid-cols-3 tw-gap-2"}>
      <p className={"tw-text-sm"}>Received</p>
      <p className={"tw-text-sm"}>Submitted</p>
      <p className={"tw-text-sm"}>Turnover</p>
      <p className={"tw-text-xl"}>{Created}</p>
      <p className={"tw-text-xl"}>{Submitted}</p>
      <p className={"tw-text-xl"}>{(Submitted/Created*100).toFixed(0)}%</p>
    </div>;
  };

  const GetOpenLateRatio = ({ Open, Late, Excluded }) => {
    const grandTotal = Late - Excluded;
    return `${(grandTotal/Open*100).toFixed(0)}%`;
  };
  const GetComingDue = ({ Open, ComingDue }) => {
    return ComingDue;
  };
  const GetLate = ({ Open, Late }) => {
    if (Open === 0 ) {
      return '0 (0%)';
    }
    return `${Late} (${(Late/Open*100).toFixed(0)}%)`;
  };
  const GetVeryLate = ({ Open, VeryLate }) => {
    if (Open === 0 ) {
      return '0 (0%)';
    }
    return `${VeryLate} (${(VeryLate/Open*100).toFixed(0)}%)`;
  };

  return (
    <>
      <h1>Lates Report</h1>

      {!config.externalAdmin &&
          <>
            <h4>Rolling Received / Submission / Turnover %</h4>
            <div className={"tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-8 tw-my-6"}>
              <DashboardCard title={lastThreeMonths && lastThreeMonths.result && lastThreeMonths.result[0].MonthName || "---"} value={lastThreeMonths && lastThreeMonths.result && get3MonthSummaryData(lastThreeMonths.result[0])}/>
              <DashboardCard title={lastThreeMonths && lastThreeMonths.result && lastThreeMonths.result[1].MonthName || "---"} value={lastThreeMonths && lastThreeMonths.result && get3MonthSummaryData(lastThreeMonths.result[1])}/>
              <DashboardCard title={lastThreeMonths && lastThreeMonths.result && lastThreeMonths.result[2].MonthName || "---"} value={lastThreeMonths && lastThreeMonths.result && get3MonthSummaryData(lastThreeMonths.result[2])}/>
              <DashboardCard title={"Total"} value={lastThreeMonths && lastThreeMonths.result && get3MonthSummaryData(lastThreeMonths.result[3])}/>
            </div>

            {line}
          </>
      }


      <h4>Live</h4>

      <div className={"tw-mt-6 lg:tw-grid tw-grid-cols-2 tw-gap-8 tw-justify-start"}>

        <div className={"tw-mb-10 tw-border tw-border-primary tw-rounded-lg tw-shadow-lg tw-p-4"}>
          <div className={"tw-grid tw-grid-cols-2 tw-h-full"}>
            <div className={"tw-flex tw-flex-col tw-justify-center tw-gap-2"}>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-bg-fs-green tw-w-8 tw-h-3 tw-inline-block"}/>
                <p>{`OK - ${liveDataCalc ? liveDataCalc.ok : ""} (${liveDataCalc ? liveDataCalc.okPercent.toFixed(0) : ""}%)`}</p>
              </div>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-bg-yellow-400 tw-w-8 tw-h-3 tw-inline-block"}/>
                <p>{`Coming Due - ${liveDataCalc ? liveDataCalc.due : ""} (${liveDataCalc ? liveDataCalc.duePercent.toFixed(0) : ""}%)`}</p>
              </div>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-bg-fs-red tw-w-8 tw-h-3 tw-inline-block"}/>
                <p>{`Late - ${liveDataCalc ? liveDataCalc.late : ""} (${liveDataCalc ? liveDataCalc.latePercent.toFixed(0) : ""}%)`}</p>
              </div>
            </div>
            <div className={""}>
              {livePieData && <Pie data={livePieData} options={livePieOptions}/>}
            </div>
          </div>
        </div>


        <div className={"tw-mb-10 tw-border tw-border-primary tw-rounded-lg tw-shadow-lg tw-p-4"}>
          <div className={"tw-grid tw-grid-cols-2 tw-h-full"}>
            <div className={"tw-flex tw-flex-col tw-justify-center tw-gap-2"}>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-w-8 tw-h-3 tw-inline-block"} style={{ backgroundColor: '#F8ED00' }}/>
                <p>{`Late > 30 days - ${liveDataCategoryCalc ? liveDataCategoryCalc.late30 : ""} (${liveDataCategoryCalc ? liveDataCategoryCalc.late30Percent.toFixed(0) : ""}%)`}</p>
              </div>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-bg-yellow-400 tw-w-8 tw-h-3 tw-inline-block"}/>
                <p>{`Late > 45 days - ${liveDataCategoryCalc ? liveDataCategoryCalc.late45 : ""} (${liveDataCategoryCalc ? liveDataCategoryCalc.late45Percent.toFixed(0) : ""}%)`}</p>
              </div>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-bg-fs-red tw-w-8 tw-h-3 tw-inline-block"}/>
                <p>{`Late > 180 days - ${liveDataCategoryCalc ? liveDataCategoryCalc.late180 : ""} (${liveDataCategoryCalc ? liveDataCategoryCalc.late180Percent.toFixed(0) : ""}%)`}</p>
              </div>
              <div className={"tw-flex tw-items-center tw-gap-2"}>
                <span className={"tw-bg-black tw-w-8 tw-h-3 tw-inline-block"}/>
                <p>{`Excluded - ${liveDataCategoryCalc ? liveDataCategoryCalc.excluded : ""} (${liveDataCategoryCalc ? liveDataCategoryCalc.excludedPercent.toFixed(0) : ""}%)`}</p>
              </div>
            </div>
            <div className={""}>
              {lateCategoryPieData && <Pie data={lateCategoryPieData} options={livePieOptions}/>}
            </div>
          </div>
        </div>
      </div>

      <div className={"tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 md:tw-gap-8 xl:tw-gap-16 tw-my-6"}>
        <DashboardCard title={"All Open"} value={liveData?.result.Open} secondValue={liveData?.result.Open>0 ? `avg ${Math.round(liveData?.result.OpenContacts / liveData?.result.Open)} contacts` : null}
          onClick={() => window.open("/reports/late/list/allopen","_blank")} />
        <DashboardCard title={"Late > 30 days"} value={liveData && liveData.result && GetLate(liveData.result)} secondValue={liveData?.result.Late>0 ? `avg ${Math.round(liveData?.result.LateContacts / liveData?.result.Late)} contacts` : null} onClick={() => window.open("/reports/late/list/late","_blank")}/>
        <DashboardCard title={"Coming Due (in 5 days)"} value={liveData && liveData.result && GetComingDue(liveData.result)} secondValue={liveData?.result.ComingDue>0 ? `avg ${Math.round(liveData?.result.ComingDueContacts / liveData?.result.ComingDue)} contacts` : null} onClick={() => window.open("/reports/late/list/comingdue","_blank")}/>
        {/*<DashboardCard title={"Late > 45 days"} value={liveData && liveData.result && GetVeryLate(liveData.result)} secondValue={liveData?.result.VeryLate>0 ? `avg ${Math.round(liveData?.result.VeryLateContacts / liveData?.result.VeryLate)} contacts` : null} onClick={() => window.open("/reports/late/list/late45","_blank")}/>*/}
        {/*<DashboardCard title={`Late ratio (excl. ${liveData && liveData.result && liveData.result.Excluded || "---"})`} value={liveData && liveData.result && GetOpenLateRatio(liveData.result)}*/}
        {/*  secondValue={liveData?.result.Excluded>0 ? `avg ${Math.round(liveData?.result.ExcludedContacts / liveData?.result.Excluded)} contacts` : null}*/}
        {/*  onClick={() => window.open("/reports/late/list/excluded","_blank")}/>*/}
        <DashboardCard title={"Submitted last 30 days"} value={liveData && liveData.result && liveData.result.SubmittedLastThirtyDays} secondValue={liveData && liveData.result && `Last 5 days: ${liveData.result.SubmittedLastFiveDays}`}/>
      </div>

      {!config.externalAdmin &&
          <>
            {line}

            <h4 className={"tw-mt-8"}>Monthly</h4>
            <div className="tw-mt-4 tw-flex tw-gap-4 tw-text-lg tw-items-center tw-h-14">
              <p>Reporting period</p>
              <select className={"tw-rounded-lg"} value={selectedMonth} onChange={(e) => changeMonth(e.target.value)}>
                {monthSelections.map(monthSelection =>
                  <option key={monthSelection.id} value={monthSelection.id}>{monthSelection.display}</option>
                )}
              </select>
              {dataLoading && <CircularProgress color={"primary"}/>}
            </div>

            <div className={"tw-mt-6 tw-w-1/2 tw-mx-auto tw-mb-10 tw-border tw-border-primary tw-rounded-lg tw-shadow-lg tw-p-4"}>
              {timePieData && <Pie data={timePieData} options={MonthlyPieOptions} height={60}/>}
            </div>

            <div className={"tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 md:tw-gap-8 xl:tw-gap-16 tw-my-6"}>
              <DashboardCard title={"Received"} value={timeData && timeData.result && timeData.result.Summary.Received}/>
              <DashboardCard title={"Submitted"} value={timeData && timeData.result && timeData.result.Summary.Submitted}/>
              <DashboardCard title={"Late"} value={timeData && timeData.result && `${timeData.result.Summary.Late} (${timeData.result.Summary.LatePercent.toFixed(0)}%)`}/>
              <DashboardCard title={"Turnover"} value={timeData && timeData.result && `${timeData.result.Summary.Turnover.toFixed(0)}%`}/>
            </div>

            {/*<div className={"tw-my-6"}>*/}
            {/*  <Table data={timeData && timeData.result && timeData.result.Inspectors || []}/>*/}
            {/*</div>*/}


          </>
      }
    </>
  );

}