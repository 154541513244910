import React, { useEffect, useMemo, useState } from 'react';
import Table from "./Table";
import LoadingAnimations from "../Loaders/Skeleton";
import useSWR from "swr";
import { config } from "../../core";

const columns = [
  { Header: "Time", accessor: "time", minWidth: 140 },
  { Header: "User", accessor: "User.Name", minWidth: 170 },
  { Header: "Description", accessor: "Description" },
];


export default function InvoiceQuickDetail({ item, getInvoiceDetailUrl }) {

  const [data, setData] = useState(null);

  const dataLoaded = (data, key, config) => {
    if (data && data.result) {
      setData(JSON.parse(data.result.Data));
    }
  };

  useSWR(`${getInvoiceDetailUrl(item.Id)}`, { refreshInterval: 0, revalidateOnFocus: false, onSuccess: dataLoaded });

  if (data === null) {
    return (
      <div style={{ width: "550px" }}>
        <LoadingAnimations height={40}/>
      </div>
    );

  }

  const getCreditsTotal = () => {
    if (data && data.Credits) {
      let total = 0;
      for (let i = 0; i < data.Credits.length; i++) {
        total += data.Credits[i].Amount;
      }
      return `($${total / 100})`;
    }
    return '0';
  };

  const getCreditsCount = () => {
    if (data && data.Credits) {
      return data.Credits.length;
    }
    return '0';
  };

  return (
    <div className={"tw-p-10 tw-rounded-lg tw-shadow-lg"} style={{ width: "550px" }}>
      <table>
        <thead>
          <tr>
            <th className={"tw-py-1 tw-pr-6 tw-text-left"}>Inspection Type</th>
            <th className={"tw-py-1 tw-pr-6 tw-text-left"}>Number Submitted</th>
            <th className={"tw-py-1 tw-text-left"}>Price</th>
          </tr>
        </thead>
        <tbody>
          {data.Types.map(type =>
            <tr key={type.InspectionType.ID}>
              <td className={""}>{type.InspectionType.InspectionType}</td>
              <td className={"tw-text-right tw-pr-6"}>{type.InspectionCount}</td>
              <td className={"tw-text-right tw-pr-6"}>${type.Price / 100}</td>
            </tr>
          )}
          <tr className={""}>
            <td className={"tw-font-bold"}>Inspections Sub-total</td>
            <td className={"tw-text-right tw-pr-6 tw-font-bold"}>{data.Inspections ? data.Inspections.length : 0}</td>
            <td className={"tw-text-right tw-pr-6 tw-font-bold"}>${data.InspectionTotalPrice / 100}</td>
          </tr>
          <tr/>
          {data.Additional && data.Additional.map(addItem =>
            <tr className={""} key={addItem.AdditionalItem.Id}>
              <td className={""}>{addItem.AdditionalItem.Item}</td>
              <td className={"tw-text-right tw-pr-6"}>{addItem.InspectionCount}</td>
              <td className={"tw-text-right tw-pr-6"}>${addItem.Price / 100}</td>
            </tr>
          )}
          <tr>
            <td className={"tw-font-bold"}>Additional Costs Total</td>
            <td>&nbsp;</td>
            <td className={"tw-text-right tw-pr-6 tw-font-bold"}>${data.AdditionalItemsTotal / 100}</td>
          </tr>
          <tr>
            <td className={"tw-font-bold"}>Credits</td>
            <td className={"tw-text-right tw-pr-6 tw-font-bold"}>{getCreditsCount()}</td>
            <td className={"tw-text-right tw-pr-6 tw-font-bold"}>{getCreditsTotal()}</td>
          </tr>
          <tr/>
          <tr className={"tw-pt-4"}>
            <td className="tw-font-bold">INVOICE TOTAL</td>
            <td>&nbsp;</td>
            <td className="tw-font-bold tw-text-right tw-pr-6">${item.Total / 100}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}