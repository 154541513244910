import React, { useState } from 'react';
import useSWR, { mutate } from "swr";
import { config } from "../core";
import { dateToString } from "../utilities/dateTime";
import { DateTime, Duration } from "luxon";
import { Bar, Pie } from "react-chartjs-2";
import 'chartjs-plugin-colorschemes';
import { Button, IconButton } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';

/*
        PeriodThisWeek = 0
		PeriodThisMonth = 1
		PeriodThisQuarter = 2
		PeriodLastWeek = 3
		PeriodLastMonth = 4
		PeriodLastQuarter = 5
 */

export default function IncomingInspections(text, opts) {

  const [period, setPeriod] = useState(0);
  const [stacked, setStacked] = useState(true);

  const cols = [
    { Header: "Company", accessor: "name" },
    { Header: "Date", accessor: "date" },
    { Header: "Count", accessor: "count" },
  ];

  const [barData, setBarData] = useState(null);
  const [pieData, setPieData] = useState(null);

  const makeDateArray = (from, to) => {
    let res = [];
    let thisDay = from;
    let i = 0;
    while (thisDay < to) {
      res.push(dateToString(thisDay.toJSDate()));
      thisDay = thisDay.plus(Duration.fromISO(`P1D`).toObject());
    }
    return res;
  };

  const dataLoaded = (data, key, config) => {
    if (!data || !data.result.data) {
      setBarData(null);
      setPieData(null);
      return;
    }
    const incoming = data.result.data;
    const from = new DateTime.fromISO(data.result.from);
    const to = new DateTime.fromISO(data.result.to);

    const dates = makeDateArray(from, to);

    let companiesSet = new Set([]);

    incoming.forEach(row => {
      companiesSet.add(row.name);
    });

    const companies = Array.from(companiesSet).sort();

    let barDataSets = [];
    let pieDataSets = [
      {
        data: [],
      }];

    companies.forEach(company => {
      let companyCount = 0;
      let dataPoint = { label: company, data: [] };
      dates.forEach(date => {
        let count = 0;
        for (let i = 0; i < incoming.length; i++) {
          const row = incoming[i];
          if (company === row.name && date === dateToString(new Date(row.date))) {
            count = row.count;
            break;
          }
        }
        dataPoint.data.push(count);
        companyCount += count;
      });
      barDataSets.push(dataPoint);
      pieDataSets[0].data.push(companyCount);
    });
    setBarData({ labels: dates, datasets: barDataSets });
    setPieData({ labels: Array.from(companies), datasets: pieDataSets });
  };

  const { data: incoming } = useSWR(`${config.api.baseUrl}/reports/incoming?period=${period}`, {
    onSuccess: dataLoaded,
  });

  const refreshClick = () => {
    mutate(`${config.api.baseUrl}/reports/incoming?period=${period}`);
  };

  const barOptions = {
    scales: {
      yAxes: [
        {
          stacked: stacked,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: stacked,
        },
      ],
    },
    plugins: {
      colorschemes: {
        scheme: 'brewer.DarkTwo8'
      }
    }
  };

  const pieOptions = {
    plugins: {
      colorschemes: {
        scheme: 'brewer.DarkTwo8'
      }
    }
  };

  return (
    <>
      <h1>Incoming Inspections</h1>

      <div className="tw-mt-10 tw-flex tw-gap-4 tw-text-lg tw-items-baseline">
        <p>Reporting period</p>
        <select className="select" value={period}
          onChange={(e) => setPeriod(e.target.value)}>
          <option value="0">This week</option>
          <option value="3">Last week</option>
          <option value="1">This month</option>
          <option value="4">Last month</option>
          <option value="2">This quarter</option>
          <option value="5">Last quarter</option>
        </select>
        <div className={"tw-mt-2"}>
          <IconButton><RefreshIcon onClick={refreshClick}/></IconButton>
        </div>
      </div>

      {barData && barData.datasets.length > 0 ?
        <div className={"tw-my-10 tw-p-5 tw-border-2 tw-shadow-lg tw-rounded-lg"}>
          <div className={"tw-float-right"}>
            <label htmlFor="stacked">Stacked</label>
            <input className="tw-ml-2" type="checkbox" id="stacked" checked={stacked}
              onChange={(e) => setStacked(e.target.checked)}/>
          </div>
          <Bar data={barData} options={barOptions}/>
        </div>
        : <div className={"tw-my-20 tw-text-center"}>
          <h3>No data found - please try another time period</h3>
        </div>
      }
      {pieData &&
            <div className={"tw-my-10 tw-p-5 tw-border-2 tw-shadow-lg tw-rounded-lg"}>
              <Pie data={pieData} options={pieOptions}/>
            </div>
      }
    </>
  );


}