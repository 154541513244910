import React, { useMemo } from 'react';
import useSWR from "swr";
import { config } from "../core";
import { Pie } from "react-chartjs-2";
import { useSortBy, useTable } from "react-table";

const red = '#d71433';
const yellow = '#F8ED00';
const amber = 'rgba(251,191,36)';
const orange = '#f57c00';
const green = '#94ba33';
const black = '#000000';

const lateColorFor = (assigned,late) => {

  const latePercent = assigned+late === 0 ? 0 : late / (assigned+late)*100;
  if (latePercent < 12) {
    return yellow;
  } else if (latePercent < 20) {
    return orange;
  } else {
    return red;
  }
};


const InspectorTable = ({ data }) => {

  const inspectorPieOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };

  const getLatePercent = (row) => {
    if (row.Assigned === 0) {return "--";}
    return `${(row.Late / row.Assigned * 100).toFixed(0)}%`;
  };

  const columns = useMemo(() => [
    { Header: "Inspector", accessor: "Inspector" },
    { Header: "Assigned", accessor: "Assigned" },
    { Header: "Late", accessor: "Late" ,
      Cell: props => `${props.row.original.Late} (${props.row.original.LatePercent.toFixed(0)}%)`
    },
    { Header: "", accessor: "chart",
      Cell: props => {
        const assigned = Number(props.row.original.Assigned-props.row.original.Late);
        const late = Number(props.row.original.Late) || 0;
        return <div className={"tw-w-20 tw-h-14"}><Pie
          data={{
            datasets: [{
              data: [assigned,late],
              backgroundColor: [
                green,lateColorFor(assigned,late)
              ]
            }],
            labels: ["OK", "Late"]
          }}
          options={inspectorPieOptions}
        /></div>;
      }
    },
  ],[]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns, data, initialState: {
    },
  }, useSortBy);
  return (
    <table
      className="tw-mt-10 tw-min-w-full tw-divide-y tw-divide-gray-200"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup, i) =>
          <tr key={`${i}`}
            className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) =>
              <th key={`${i}-${j}`} width={column.minWidth > 0 ? column.minWidth : null}
                className={`tw-px-6 tw-py-3 tw-text-left tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider tw-font-bold 
                      ${column.isSorted ? column.isSortedDesc ? `sort-desc` : `sort-asc` : ``}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")} {
                  column.isSorted
                    ? column.isSortedDesc
                      ?
                      <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-down"/>
                      </div>
                      : <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-up"/>
                      </div>
                    : ''
                }
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={`${i}`}{...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                let classnames = "tw-px-6 tw-py-2 ";
                i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                return (
                  <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const StateTable = ({ data }) => {

  const statePieOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };

  const getLatePercent = (row) => {
    if (row.Assigned === 0) {return "--";}
    return `${(row.Late / row.Assigned * 100).toFixed(0)}%`;
  };

  const columns = useMemo(() => [
    { Header: "State", accessor: "State" },
    { Header: "Assigned", accessor: "Assigned" },
    { Header: "Late", accessor: "Late" ,
      Cell: props => `${props.row.original.Late} (${getLatePercent(props.row.original)})`
    },
    { Header: "", accessor: "chart",
      Cell: props => {
        const assigned = Number(props.row.original.Assigned-props.row.original.Late);
        const late = Number(props.row.original.Late) || 0;
        return <div className={"tw-w-20 tw-h-14"}><Pie
          data={{
            datasets: [{
              data: [assigned,late],
              backgroundColor: [
                green,lateColorFor(assigned,late)
              ]
            }],
            labels: ["OK", "Late"]
          }}
          options={statePieOptions}
        /></div>;
      }
    },
  ],[]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns, data, initialState: {
      sortBy: [
        {
          id: "Inspector",
          desc: false
        }
      ]
    },
  }, useSortBy);
  return (
    <table
      className="tw-mt-10 tw-min-w-full tw-divide-y tw-divide-gray-200"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup, i) =>
          <tr key={`${i}`}
            className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) =>
              <th key={`${i}-${j}`} width={column.minWidth > 0 ? column.minWidth : null}
                className={`tw-px-6 tw-py-3 tw-text-left tw-leading-4 tw-uppercase tw-tracking-wider tw-font-bold 
                      ${column.isSorted ? column.isSortedDesc ? `sort-desc` : `sort-asc` : ``}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")} {
                  column.isSorted
                    ? column.isSortedDesc
                      ?
                      <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-down"/>
                      </div>
                      : <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-up"/>
                      </div>
                    : ''
                }
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={`${i}`}{...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                let classnames = "tw-px-6 tw-py-2 ";
                i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                return (
                  <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const LateDash = () => {

  const livePieOptions = {
    legend: {
      display: false,
      position: 'left',
    }
  };

  const { data: dashData } = useSWR(`${config.api.baseUrl}/reports/inspectionlates/dash`, {
    refreshInterval: 60000,
    // refreshWhenHidden: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  const livePieData = useMemo(() => {
    if (!dashData?.result) {
      return null;
    }
    return {
      datasets: [{
        data: [dashData?.result.Summary.Open-dashData?.result.Summary.Late,dashData?.result.Summary.Late],
        backgroundColor: [
          green,lateColorFor(dashData?.result.Summary.Open-dashData?.result.Summary.Late,dashData?.result.Summary.Late)
        ]
      }],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        "OK","Late"
      ]
    };

  }, [dashData]);

  if (!dashData?.result) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Daily Support Dashboard</h1>

      <div className={"tw-max-w-screen-md tw-mx-auto tw-mb-10 tw-border tw-border-primary tw-rounded-lg tw-shadow-lg tw-p-4"}>
        <div className={"tw-grid tw-grid-cols-2 tw-h-full"}>
          <div className={"tw-flex tw-flex-col tw-justify-center tw-gap-2"}>
            <div className={"tw-flex tw-items-center tw-gap-2"}>
              <span className={"tw-bg-fs-green tw-w-8 tw-h-3 tw-inline-block"}/>
              <p>{`OK - ${dashData?.result.Summary.Open - dashData?.result.Summary.Late} (${(100-dashData?.result.Summary.LatePercent).toFixed(0)}%)`}</p>
            </div>
            <div className={"tw-flex tw-items-center tw-gap-2"}>
              <span className={"tw-bg-fs-red tw-w-8 tw-h-3 tw-inline-block"}/>
              <p><a href={"/reports/late/list/late"} target={"_blank"} rel="noreferrer">{`Late - ${dashData?.result.Summary.Late} (${dashData?.result.Summary.LatePercent.toFixed(0)}%)`}</a></p>
            </div>
          </div>
          <div className={""}>
            {livePieData && <Pie data={livePieData} options={livePieOptions}/>}
          </div>
        </div>
      </div>

      <div className={"lg:tw-grid tw-grid-cols-2 tw-gap-10"}>
        <StateTable data={dashData?.result.States}/>
        <InspectorTable data={dashData?.result.Inspectors}/>
      </div>

    </div>
  );
};

export default LateDash;