import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { config } from "~/core";
import Table from "../components/tables/Table";
import InspectionPreAddNoteModal from "../components/Modal/InspectionPreAddNote";
import { apiProvider } from "../api/core/provider";
import EditInspectionPriceModal from "../components/Modal/EditInspectionPrice";
import formatMoney from "../utilities/formatMoney";

export default function InspectorInvoicePrices() {

  const BASE_URL = "/api/" + config.api.version;

  const editPrice = type => {
    setEditingInspectionType(type);
    setEditPriceOpen(true);
  };

  const savePriceHandler = (inspectionType, newPrice, updateAll) => {
    apiProvider.post(`accounting/prices/inspector/${selectedInspector}`,
      {
        type: inspectionType.ID,
        price: Number(newPrice * 100),
        updateAll: updateAll
      }).then((data) => {
      mutate(`${BASE_URL}/accounting/prices/inspector/${selectedInspector}`);
    }
    ).catch((e) => {
      console.log("Error saving price - ", e);
    });
  };

  const [inspectors, setInspectors] = useState(null);
  const [selectedInspector, setSelectedInspector] = useState(null);
  const [editingInspectionType, setEditingInspectionType] = useState(null);
  const [editPriceOpen, setEditPriceOpen] = useState(false);
  const [prices, setPrices] = useState(null);

  const cols = [
    { Header: "Inspection Type", accessor: "InspectionType" },
    { Header: "Price", accessor: "FormattedPrice" },
    { Header: "", accessor: "edit" },
  ];

  const dataLoaded = (data, key, config) => {
    if (!data || !data.result) {
      return;
    }
    let prices = data.result;
    prices.sort((a, b) => {
      if (a.InspectionType < b.InspectionType) {
        return -1;
      }
      if (a.InspectionType > b.InspectionType) {
        return 1;
      }
      return 0;
    });

    setPrices(prices.map(inspectionType => {
      inspectionType.FormattedPrice = formatMoney(Number(inspectionType.Price) / 100);
      inspectionType.Price = Number(inspectionType.Price) / 100;
      inspectionType.edit =
                <button
                  className="btn btn-primary"
                  onClick={() => editPrice(inspectionType)}
                >
                    Edit
                </button>;

      return inspectionType;
    }));
  };

  const inspectorsLoaded = (data, key, config) => {
    if (data && data.result) {
      setInspectors(data.result);
    }
  };

  useSWR(() => selectedInspector ? `${BASE_URL}/accounting/prices/inspector/${selectedInspector}` : null, { onSuccess: dataLoaded });
  useSWR(`${BASE_URL}/inspectors`, { refreshInterval: 0, onSuccess: inspectorsLoaded });

  return (
    <>
      <h1>Inspector Invoice Prices</h1>

      <div className={"tw-mt-10 tw-flex tw-gap-8 tw-text-lg tw-items-baseline"}>
        <p>Inspector:</p>
        {inspectors &&
                <select className="tw-flex-grow tw-rounded-lg"
                  onChange={(e) => setSelectedInspector(e.target.value)}>
                  <option value={0}>Choose inspector..</option>
                  {inspectors && inspectors.map((inspector) =>
                    <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
                  )}
                </select>
        }
      </div>

      {prices && prices &&
            <div className={"tw-mt-14 tw-mb-10"}>
              <Table data={prices} columns={cols}/>
            </div>
      }

      <EditInspectionPriceModal inspectionType={editingInspectionType} open={editPriceOpen}
        closeHandler={() => setEditPriceOpen(false)}
        saveHandler={savePriceHandler}/>
    </>
  );
}