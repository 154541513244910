export function handleResponse(response) {
  if (response.results) {
    return response.results;
  }

  if (response.data) {
    let filename = null;
    let contentType = response.headers["content-type"];

    // Check if it's a file download and extract the filename
    const disposition = response.headers["content-disposition"];
    if (disposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    return {
      filename,
      contentType,
      data: response.data,
    };
  }

  return response;
}

export function handleError(error) {
  if (error.data) {
    return error.data;
  }
  return error;
}
