import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function LoadingAnimations(props) {

  let components = [];

  let count = props.count || 5;
  let height = props.height || '30';
  let width = props.width || '100%';

  for (let i = 0; i < count; i++) {
    let animation = i % 3 === 0 ? props.animation : false;
    components.push(<Skeleton key={i} animation={animation} height={height} width={width}/>);
  }
  return components;

}