import React, { useState, useEffect } from "react";
import { apiTest } from "~/api";

function Test() {
  const [message, setMessage] = useState("LOADING");

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const response = await apiTest.get();

    setMessage(response.data.result);
  };

  return (
    <React.Fragment>
      <h1>{message}</h1>
    </React.Fragment>
  );
}

export default Test;
