import React, { useState, useEffect } from "react";
import { config } from "../core";
import SimpleTwoColTable from "../components/SimpleTwoColTable/SimpleTwoColTable";
import InspectionHistoryTable from "../components/tables/InspectionHistoryTable";
import GoogleMapReact from 'google-map-react';
import { Dialog, DialogContent, LinearProgress, makeStyles } from "@material-ui/core";
import { AddCircle, ArrowDownward, Cancel, CloudUpload, Edit } from "@material-ui/icons";
import InspectionPreAddNoteModal from "../components/Modal/InspectionPreAddNote";
import { apiProvider } from "../api/core/provider";
import {
  appointmentToString,
  isAppointmentSet, isCancelled,
  isClosed,
  postNewNote,
  requiresInterior,
  statusToText
} from "../utilities/inspections";
import { toast, ToastContainer } from "react-toastify";
import useSWR from "swr";
import InspectionPreEditModal from "../components/Modal/InspectionPreEditModal";
import ConfirmDialog from "../components/Modal/ConfirmDialog";
import FSButton from "../components/Buttons/FSButton";
import DropdownButton from "../components/Buttons/DropdownButton";
import InspectionDocumentsTable from "../components/tables/InspectionDocumentsTable";
import { dateToString } from "../utilities/dateTime";

const MapMarker = () => <div className=""><i className="fa fa-map-pin tw-text-red-600 tw-text-5xl"/></div>;

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  startButton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  covidDowngradeButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      marginRight: theme.spacing(1),
      width: 'auto',
    },
  },
  cancelInspectionButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  hr: {
    height: 3,
    backgroundColor: "#45456e",
    marginLeft: '1.5rem',
  }
}));

export default function InspectionPreContact({ policyNo }) {

  const { data: noAccessReasons } = useSWR(`${config.api.baseUrl}/inspections/noaccessreasons`, { refreshInterval: 0 });

  const classes = useStyles();

  const [historyItems, setHistoryItems] = useState([]);
  const [showCovid, setShowCovid] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [covidDowngradeConfirmOpen, setCovidDowngradeConfirmOpen] = useState(false);
  const [covidCancelConfirmOpen, setCovidCancelConfirmOpen] = useState(false);
  const [reopenConfirmOpen, setReopenConfirmOpen] = useState(false);
  const [cancelAppointmentConfirmOpen, setCancelAppointmentConfirmOpen] = useState(false);
  const [EACancelConfirmOpen, setEACancelConfirmOpen] = useState(false);
  const [score, setScore] = useState({});
  const [inspection, setInspection] = useState(null);
  const [editingType, setEditingType] = useState(null);
  const [inspectionOpen, setInspectionOpen] = useState(false);
  const [canReopen, setCanReopen] = useState(false);
  const [canChangeInspectionType, setCanChangeInspectionType] = useState(false);
  const [recordNoAccessOpen, setRecordNoAccessOpen] = useState(false);
  const [selectedNoAccess, setSelectedNoAccess] = useState(null);
  const [externalAdmin, setExternalAdmin] = useState(false);
  const [file, setFile] = useState(null);
  const [confirmFileUploadOpen, setConfirmFileUploadOpen] = useState(false);
  const [waitingForUpload, setWaitingForUpload] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [reopenCompletedConfirmOpen, setReopenCompletedConfirmOpen] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const mapIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
  </svg>;

  const showScoring = false;

  let showStartInspectionButton = true;
  // if policyNo is set, this isn't a standalone page, but in a popup from a report
  // so don't show the start button
  if (policyNo) {
    showStartInspectionButton = false;
  }
  policyNo = policyNo || config.routeParams.pn;
  const addNewNote = (newNote) => {
    postNewNote(policyNo, newNote).then(r => {
      if (r.status < 399) {
        toast.success("Note added", {
          autoClose: 2000
        });
        // add note locally
        const newList = [].concat(historyItems);
        newNote = {
          HistoryTime: new Date(),
          User: { Id: config.user.id, Name: config.user.name },
          Description: newNote
        };
        newList.unshift(newNote);
        setHistoryItems(newList);
        mutateInspection();
      } else {
        console.log("Error adding note - " + r.data.message);
        toast.error('Unable to add note - please try again later');
      }
    });

  };

  const getAddressTitle = i => {
    return i.Address + ", " + i.City;
  };

  const saveEditHandler = data => {
    console.log({ data });
    apiProvider.put(`inspection/${policyNo}`, data).then((result) => {
      mutateInspection();
    }).catch((err) => {
      toast.error("Error saving - please try again later", {
        autoClose: 2000
      });
    });
    setEditingType(null);
  };

  const doCovidDowngrade = () => {
    saveEditHandler({ covidDowngrade: true });
  };

  const doCovidCancel = () => {
    saveEditHandler({ covidCancel: true });
  };

  const doReopen = () => {
    saveEditHandler({ reopen: true });
  };

  const doReopenCompleted = () => {
    saveEditHandler({ reopenCompleted: true });
  };

  const doCancelAppointment = () => {
    inspection.AppointmentDate = '0001-01-01T00:00:00Z';
    saveEditHandler({ cancelAppointment: true });
  };

  const confirmNoAccess = selectedOption => {
    setSelectedNoAccess(selectedOption);
    setRecordNoAccessOpen(true);
  };

  const {
    data: inspectionData,
    mutate: mutateInspection
  } = useSWR(`${config.api.baseUrl}/inspection/${policyNo}`, { refreshInterval: 0, revalidateOnFocus: false, refreshWhenOffline: false });

  useEffect(() => {
    if (!inspectionData) {
      return;
    }
    setInspection(inspectionData.result.Inspection);
    setHistoryItems(inspectionData.result.History);
    setInspectionOpen(!isClosed(inspectionData.result.Inspection.Status));
    setExternalAdmin(inspectionData.result.EA);
    setCanChangeInspectionType(inspectionData.result.CanChangeInspectionType);
    setCanReopen(inspectionData.result.CanReopen);
    setEffectiveDate(new Date(inspectionData.result.EffectiveDate));
  }, [inspectionData]);

  const calculateScores = () => {
    apiProvider.get("scoring/main", policyNo).then((result) => {
      if (!result.data.result) {
        return;
      }
      setScore(prevState => ({
        ...prevState,
        main: result.data.result.results[0].results[0].value
      }));
    });
    apiProvider.get("scoring/wind", policyNo).then((result) => {
      if (!result.data.result) {
        return;
      }
      setScore(prevState => ({
        ...prevState,
        wind: result.data.result.results[0].results[0].value
      }));
    });
    apiProvider.get("scoring/fire", policyNo).then((result) => {
      if (!result.data.result) {
        return;
      }
      setScore(prevState => ({
        ...prevState,
        fire: result.data.result.results[0].results[0].value
      }));
    });
  };

  const getEditIcon = () => {
    return inspectionOpen ? <Edit/> : null;
  };

  const createMapOptions = (maps) => {
    return {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: true
    };
  };

  const onFileChange = async (event) => {
    setFile(event.target.files[0]);
    setConfirmFileUploadOpen(true);
  };

  const doFileUpload = async () => {
    setConfirmFileUploadOpen(false);
    setFile(null);

    const data = new FormData();
    const payload = {
      policyNo: policyNo,
    };
    data.append('payload', JSON.stringify(payload));
    data.append('file', file);

    setWaitingForUpload(true);
    const res = await apiProvider.post("inspection/uploadDocument",data);
    setWaitingForUpload(false);

    if (res.data.status !== 200) {
      alert("An unexpected error occurred - please try again later");
      return;
    }

    mutateInspection();

  };


  if (inspection === null) {
    return null;
  }

  return (
    <>
      <div className="">
        <div className={"md:tw-flex md:tw-items-center"}>
          <h3 className="tw-hidden md:tw-block tw-text-2xl xl:tw-text-4xl tw-leading-6 tw-font-medium tw-mx-auto tw-text-center">
            {policyNo} - {getAddressTitle(inspection)}
          </h3>
          <div className={"tw-ml-4 tw-mt-1"}>
            {inspectionOpen && !externalAdmin && showStartInspectionButton &&
                        <FSButton color={'fs-green'} className={"tw-w-full md:tw-w-auto"} textColor={"gray-50"}
                          icon={<i className="fa fa-play"/>} onClick={() => {
                            window.location.href = `/inspections/${policyNo}/process/1`;
                          }}>Start Inspection</FSButton>}
            {!inspectionOpen && canReopen && !externalAdmin && <FSButton color={'primary'} className={"tw-w-full md:tw-w-auto"}
              icon={<i className="fa fa-lock-open"/>} onClick={() => {
                setReopenConfirmOpen(true);
              }}>Re-open Inspection</FSButton>}
          </div>
        </div>

        <div className={"lg:tw-grid lg:tw-grid-cols-2 tw-pt-4"}>
          <div>
            <SimpleTwoColTable title={"Inspection"} rightTitleComponent={inspectionData.result.HighValue && <p className={"tw-p-2 tw-bg-fs-green-dark tw-shadow-lg tw-text-white tw-uppercase tw-cursor-default"}>High Value</p>} data={[
              { a: "Policy No", b: <a target="_blank" href={`/inspections/${policyNo}`} rel="noreferrer">{policyNo}</a> },
              {
                a: "Type",
                b: inspection.InspectionType,
              },
              { a:"Effective Date", b: dateToString(effectiveDate) },
              {
                a: "Status", b: statusToText(inspection.Status), icon: inspection.Status === "completed" && !externalAdmin? <i title="Reopen Inspection" className="fa fa-lock-open"/> : null,
                onClick: () => setReopenCompletedConfirmOpen(true)
              },
              !externalAdmin && {
                a: "Private Notes", b: inspection.Notes, icon: getEditIcon(), onClick: () => {
                  setEditingType("notes");
                }
              },
              !externalAdmin && {
                a: "Inspector", b: inspection.InspectorName
              }
            ]}/>

            <SimpleTwoColTable title={"Insured"}
              data={[{ a: "Name", b: inspection.Name }, {
                a: "Address",
                b: <a className="tw-text-black" target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${inspection.FullAddress}`}>{inspection.FullAddress} <span className={"tw-pt-1 tw-h-5 tw-w-5 tw-inline-block lg:tw-hidden"}>{mapIcon}</span></a>,
                icon: getEditIcon(),
                onClick: () => {
                  setEditingType("address");
                },
              }, {
                a: "Telephone", b: <a href={`tel:${inspection.Telephone}`}>{inspection.Telephone}</a>, icon: getEditIcon(),
                onClick: () => {
                  setEditingType("phone");
                },
              },
              {
                a: "Email", b: <a href={`mailto:${inspection.Email}`}>{inspection.Email}</a>, icon: getEditIcon(),
                onClick: () => {
                  setEditingType("email");
                },
              }]}
            />

            <SimpleTwoColTable title={"Agent"} skipEmpty={false}
              data={[{ a: "Name", b: inspection.AgentContact }, {
                a: "Agency",
                b: inspection.AgentCompany
              },
              { a: "Telephone", b: <a href={`tel:${inspection.AgentPhone}`}>{inspection.AgentPhone}</a> },
              { a: "Email", b: <a href={`tel:${inspection.AgentEmail}`}>{inspection.AgentEmail}</a> }
              ]}
            />

            {inspectionData.result.PropertyInfo &&
            <SimpleTwoColTable title={"Property Lookup"} skipEmpty={false}
              data={Object.entries(inspectionData.result.PropertyInfo).map((item) => {
                return {
                  a: item[0],
                  b: item[1]
                };
              })}
            />
            }

          </div>

          <div
            className={"tw-hidden lg:tw-block tw-ml-4 tw-shadow tw-overflow-hidden tw-rounded-xl tw-h-full"}>
            <GoogleMapReact defaultCenter={{ lat: inspection.Lat, lng: inspection.Long }} defaultZoom={12} options={createMapOptions}
              bootstrapURLKeys={{ key: "AIzaSyBHDVtQHIMXzOr7xjqadRZtScytVh__xfA" }}>
              <MapMarker
                lat={inspection.Lat}
                lng={inspection.Long}
              />
            </GoogleMapReact>
          </div>

        </div>

        <div className={"lg:tw-grid lg:tw-grid-cols-2"}>

          {inspectionOpen && !externalAdmin &&
                    <div className="tw-p-6 sm:tw-flex tw-justify-between tw-gap-4">
                      <div className="tw-flex-grow">
                        <h3>Appointment <i className="fal fa-calendar-check"/></h3>
                        <div className={"tw-text-xl tw-text-gray-600"}>
                          {
                            isAppointmentSet(inspection.AppointmentDate) &&
                                    appointmentToString(inspection.AppointmentDate, inspection.AppointmentTime)
                          }
                        </div>

                        {isAppointmentSet(inspection.AppointmentDate) ?
                          <FSButton onClick={() => setCancelAppointmentConfirmOpen(true)}
                            className={"tw-mt-4 tw-w-full sm:tw-w-auto"} color="fs-red" icon={<Cancel/>}>Cancel
                                  Appointment</FSButton>
                          :
                          <FSButton onClick={() => setEditingType("appointment")}
                            className={"tw-mt-4 tw-w-full sm:tw-w-auto"} color="primary"
                            icon={<i className="fa fa-calendar-check"/>}>Schedule Appointment</FSButton>
                        }

                      </div>

                      <div className="tw-flex-grow sm:tw-border-l-4 sm:tw-pl-4">
                        <h3>No Access <i className="fal fa-door-closed"/></h3>
                        <DropdownButton className="tw-w-full sm:tw-w-auto" title={"Record No Access"} options={noAccessReasons.result || []}
                          onSelectOption={(optionSelected) => confirmNoAccess(optionSelected)}/>
                      </div>
                    </div>
          }

          {showCovid && inspectionOpen && !externalAdmin &&
                    <div className={"tw-pl-6 tw-pt-6"}>
                      <h3>COVID-19 <i className={"fad fa-virus"}/></h3>
                      <p>If this inspection cannot be completed normally due to COVID-19 / Coronavirus, please
                            choose
                            one
                            of the following options:</p>
                      <div className={"sm:tw-flex"}>
                        <FSButton color={"primary"} className={"tw-w-full tw-mt-2 sm:tw-w-auto tw-flex-grow"}
                          onClick={() => setCovidDowngradeConfirmOpen(true)}
                          disabled={!requiresInterior(inspection.InspectionTypeCode)}
                          icon={<ArrowDownward/>}>Downgrade to Basic with RCE</FSButton>
                        <FSButton color={"fs-red"}
                          className={"tw-w-full sm:tw-w-auto tw-mt-2 sm:tw-ml-2 tw-flex-grow"}
                          onClick={() => setCovidCancelConfirmOpen(true)} disabled={!inspectionOpen}
                          icon={<Cancel/>}>Cancel Inspection</FSButton>
                      </div>
                    </div>
          }

          {externalAdmin && inspectionOpen && inspection.Status !== 'completed' &&
          <div className={"tw-pl-6 tw-pt-6"}>
            <div className={"sm:tw-flex"}>
              <FSButton color={"fs-red"}
                className={"tw-w-full sm:tw-w-auto tw-mt-2 sm:tw-ml-2"}
                onClick={() => window.location.href=`/inspections/cancel/${inspection.PolicyNo}`}
                icon={<Cancel/>}>Cancel / Close Out</FSButton>
            </div>
          </div>


          }

          {/*{showScoring &&*/}
          {/*<div className={"tw-p-6"}>*/}
          {/*  <h3>Scoring <i className={"fad fa-calculator-alt"}/></h3>*/}
          {/*  <table className={"table tw-text-lg"}>*/}
          {/*    <thead>*/}
          {/*      <tr>*/}
          {/*        <td>Main</td>*/}
          {/*        <td>Wind</td>*/}
          {/*        <td>Fire</td>*/}
          {/*      </tr>*/}
          {/*    </thead>*/}
          {/*    <tbody>*/}
          {/*      <tr>*/}
          {/*        <td>{score.main ? score.main : '--'}</td>*/}
          {/*        <td>{score.wind ? score.wind : '--'}</td>*/}
          {/*        <td>{score.fire ? score.fire : '--'}</td>*/}
          {/*      </tr>*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</div>*/}
          {/*}*/}


        </div>

        <hr className={classes.hr}/>

        <input
          type="file"
          ref={hiddenFileInput}
          hidden
          name="fileupload"
          accept="application/pdf"
          onChange={onFileChange}
        />

        <div className={"tw-pl-6"}>
          <div className={"tw-flex tw-justify-between tw-items-baseline"}>
            <h3>Documents <i className={"fal fa-folder-open"}/></h3>
            {inspectionOpen && <FSButton color={"primary"}
              icon={<CloudUpload/>}
              onClick={() => hiddenFileInput.current.click()}>Upload</FSButton>}
          </div>
          <InspectionDocumentsTable items={inspectionData?.result.Documents} policyNo={policyNo} mutateInspection={mutateInspection}/>
        </div>

        {inspectionOpen && <hr className={classes.hr}/>}

        <div className={"tw-pl-6 tw-pt-6"}>
          <div className={"tw-flex tw-justify-between tw-items-baseline"}>
            <h3>History <i className={"fad fa-history"}/></h3>
            {(inspectionOpen || isCancelled(inspection.Status)) && <FSButton color={"primary"}
              icon={<AddCircle/>}
              onClick={() => setAddNoteOpen(true)}>Add</FSButton>}
          </div>
          <div className={"table table-sm tw-text-xs md:tw-text-sm"}>
            <InspectionHistoryTable items={historyItems}/>
          </div>
        </div>

      </div>

      <InspectionPreAddNoteModal open={addNoteOpen} closeHandler={() => setAddNoteOpen(false)}
        newNoteHandler={addNewNote}/>

      <InspectionPreEditModal open={editingType} editingType={editingType}
        closeHandler={() => setEditingType(null)} saveHandler={saveEditHandler}
        inspection={inspection}/>

      <ConfirmDialog title="Downgrade to Basic?" open={covidDowngradeConfirmOpen}
        setOpen={setCovidDowngradeConfirmOpen} onConfirm={() => doCovidDowngrade()}>
                Are you sure you want to downgrade this inspection to Basic with RCE due to Covid concerns?
      </ConfirmDialog>

      <ConfirmDialog title="Cancel inspection?" open={covidCancelConfirmOpen}
        setOpen={setCovidCancelConfirmOpen} onConfirm={() => doCovidCancel()}>
                Are you sure you want to cancel this inspection due to Covid concerns?
      </ConfirmDialog>

      <ConfirmDialog title="Re-open inspection?" open={reopenConfirmOpen}
        setOpen={setReopenConfirmOpen} onConfirm={() => doReopen()}>
                Are you sure you want to re-open this inspection?
      </ConfirmDialog>

      <ConfirmDialog title="Re-open inspection?" open={reopenCompletedConfirmOpen}
        setOpen={setReopenCompletedConfirmOpen} onConfirm={() => doReopenCompleted()}>
        Are you sure you want to re-open this inspection?
      </ConfirmDialog>

      <ConfirmDialog title="Cancel appointment?" open={cancelAppointmentConfirmOpen}
        setOpen={setCancelAppointmentConfirmOpen} onConfirm={() => doCancelAppointment()}>
                Are you sure you want to cancel this appointment?
      </ConfirmDialog>

      <ConfirmDialog title="Record no access?" open={recordNoAccessOpen}
        setOpen={setRecordNoAccessOpen} onConfirm={() => saveEditHandler({ noAccessReason: selectedNoAccess })}>
            Record: {selectedNoAccess}?
      </ConfirmDialog>

      <ConfirmDialog title="Cancel?" open={EACancelConfirmOpen}
        setOpen={setEACancelConfirmOpen} onConfirm={() => saveEditHandler({ eacancel: true })}>
            Record no access?
      </ConfirmDialog>


      <ConfirmDialog title="Upload document?" open={confirmFileUploadOpen}
        setOpen={setConfirmFileUploadOpen} onConfirm={() => doFileUpload()}>
        Upload document <strong>{file?.name}</strong>?
      </ConfirmDialog>

      <Dialog open={waitingForUpload} maxWidth={"lg"}>
        <DialogContent>
          <div className={"tw-h-32 tw-flex tw-flex-col tw-gap-12 tw-px-4"}>
            <p className={"tw-text-xl tw-text-center"}>Uploading document, please wait</p>
            <div className={"tw-w-full"}>
              <LinearProgress />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />

    </>
  );
}