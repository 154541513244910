/* eslint-disable react/display-name */

import React, { useRef, useState, useMemo, useEffect } from 'react';
import { config } from "../core";
import FSButton from "../components/Buttons/FSButton";
import axios from "axios";
import DashboardCard from "../components/cards/DashboardCard";
import InspectionReportTable from "../components/tables/InspectionReportTable";
import Table from "../components/tables/Table";
import LoadingAnimations from "../components/Loaders/Skeleton";


export default function OrchidOpenList(factory, deps) {

  const policyListForm = useRef();

  const notFoundCols = [
    {
      Header: "Policy #", accessor: "PolicyNo"
    }
  ];

  const [results, setResults] = useState(null);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = () => {
    axios.get(`${config.api.baseUrl}/reports/orchidopens`).then((response) => {
      setResults(response.data.result);
    });
  };

  const notFoundMapped = useMemo(() => {
    if (!results) {
      return [];
    }
    return results.NotFounds?.map((policyNo) => {
      return { "PolicyNo": policyNo };
    });
  }, [results]);

  const dupesMapped = useMemo(() => {
    if (!results) {
      return [];
    }
    return results.Duplicates?.map((policyNo) => {
      return { "PolicyNo":policyNo };
    });
  },[results]);


  return (
    <>
      <h1>Orchid List Status</h1>
      <div className={"tw-flex tw-justify-between"}>
        <h3>Status of Orchid Open List</h3>
        {results && <FSButton icon={<i className={"fa fa-download"}/>} color={"primary"}
          onClick={() => window.open(`/reports/late/orchidopens/csv`)}>Download CSV</FSButton>}
      </div>

      {results ?
        <>

          <div className={"tw-grid tw-grid-cols-2 tw-gap-4 md:tw-gap-8 xl:tw-gap-16 tw-my-6"}>
            {/*<DashboardCard title={"Requested"} value={results.Requested}/>*/}
            <DashboardCard title={"Found"} value={results.Total}/>
            <DashboardCard title={"Submitted"} value={results.Data?.filter(item => item.Status === "Submitted").length}/>
            {/*<DashboardCard title={"Late"} value={`${results.Late} (${(results.Late / results.Total*100).toFixed(1)}%)`}/>*/}
          </div>

          <InspectionReportTable data={results.Data} mutate={getStatus} initialSort={"OrderID"}/>

          {results.NotFounds &&
                <>
                  <h2 className={"tw-mt-8"}>Not Found / Awaiting Order from API - {results.NotFounds.length}</h2>
                  <Table columns={notFoundCols} data={notFoundMapped}
                  />
                </>
          }

          {results.Duplicates &&
                <>
                  <h2 className={"tw-mt-8"}>Duplicates - {results.Duplicates.length}</h2>
                  <Table columns={notFoundCols} data={dupesMapped}
                  />
                </>
          }
        </> :
        <LoadingAnimations/>
      }

    </>
  );

}