import React from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import FSButton from "./FSButton";

export default function DropdownButton({ title, className, color, options, onSelectOption }) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    setAnchorEl(e.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const optionChosen = option => {
    handleClose();
    onSelectOption(option);
  };

  return (
    <>
      <FSButton className={className} color={color || "primary"} endIcon={<ArrowDropDown/>} onClick={handleClick}>{title}</FSButton>
      {/*<Button variant={'contained'} color={'primary'} endIcon={<ArrowDropDown/>} aria-controls="action-menu"*/}
      {/*  aria-haspopup="true" onClick={handleClick}>*/}
      {/*  {title}*/}
      {/*</Button>*/}
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) =>
          <MenuItem key={option} onClick={() => optionChosen(option)}>{option}</MenuItem>
        )}
      </Menu>
    </>
  );
}
