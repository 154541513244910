import React from "react";

const DashboardCard = ({ title, subtitle, value, secondValue, color, disabled, onClick } ) => {
  return (
    <>
      <div
        className={`${disabled ? "tw-shadow-sm" : "tw-shadow-xl"} tw-border tw-rounded-xl tw-overflow-hidden tw-shadow-sm tw-pb-4 ${onClick ? "tw-cursor-pointer hover:tw-border-primary" : ""}`}
        onClick={onClick}>
        <div
          className={`${color ? color : "tw-bg-primary"} ${disabled ? "tw-bg-opacity-50 " : ""} tw-w-full tw-p-2`}/>
        <div className={`${disabled ? "tw-bg-gray-400 tw-text-gray-500" : ""} tw-container `}>
          <p className="tw-text-base tw-pt-2">
            {title ?
              title
              : null}
          </p>
          <p className="tw-hidden lg:tw-block tw-text-sm tw-pb-1 tw-text-gray-500">
            {subtitle ?
              subtitle
              : null}
          </p>
          <p className="tw-text-3xl">{value ? value : "---"}</p>
          {secondValue && <p className="tw-text-sm">{secondValue}</p>}
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
