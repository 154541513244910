import { ApiCore } from "./core";
import { apiProvider } from "./core/provider";

const url = "inspections";

const apiInspections = new ApiCore({
  list: false,
  get: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

apiInspections.deleteDocument = function(policyNo,documentID) {
  return apiProvider.delete(`/inspection/document/${policyNo}`,documentID);
};

apiInspections.getPlannedInspections = function(inspectorID, date, showInspectionsWithNoAppointments) {
  return apiProvider.get(`inspections/planned?inspectorID=${inspectorID}&date=${date}&noappointment=${showInspectionsWithNoAppointments}`);
};

apiInspections.getOpenInspectionsMap = function(types,showRecentlyCompleted) {
  return apiProvider.list(`inspections/openmap?types=${types}&recentlycompleted=${showRecentlyCompleted}`);
};


apiInspections.getRoute = function(inspectorID, date) {
  return apiProvider.get(`inspections/route?inspectorID=${inspectorID}&date=${date}`);
};


apiInspections.calculateRoute = function(inspectorID, date, startAddress, endAddress, startLocation, endLocation, desiredStartTime, desiredEndTime, planInspectionsWithNoAppointments) {

  const params = new URLSearchParams(
    {
      inspectorID,
      date,
      startAddress,
      endAddress,
      startLocation: `${startLocation?.lat},${startLocation?.lng}`,
      endLocation: `${endLocation?.lat},${endLocation?.lng}`,
      desiredStartTime,
      desiredEndTime,
      noappointment: planInspectionsWithNoAppointments
    }
  );

  return apiProvider.get(`inspections/calcroute?${params.toString()}`) ;
};

apiInspections.assignInspectionsFromMap = function(inspectorID, date, inspections) {
  return apiProvider.post(`inspections/assignfrommap`, { inspections, inspectorID, date });
};

apiInspections.unAssignInspectionsFromMap = function(inspections) {
  return apiProvider.post(`inspections/unassignfrommap`, { inspections });
};

apiInspections.cancelAppointment = function(policyNo) {
  return apiProvider.post(`inspections/cancelappointment`, { policyNo });
};

apiInspections.cancelAppointments = function(inspections) {
  return apiProvider.post(`inspections/cancelappointments`, { inspections });
};




export { apiInspections };
