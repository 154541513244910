import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Modal,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { CancelRounded, Save, SaveRounded } from "@material-ui/icons";

export default function InspectionPreAddNoteModal({ open, closeHandler, newNoteHandler }) {

  const [note, setNote] = useState("");

  const saveNote = () => {
    newNoteHandler(note);
    setNote("");
    closeHandler();
  };

  return (
    <>
      <Dialog open={open} onClose={closeHandler} maxWidth='sm' fullWidth={true}>

        <DialogContent>
          <DialogContentText>
                        Add Note
          </DialogContentText>

          <TextareaAutosize autoFocus className="tw-w-full" rowsMin={6} placeholder="Notes" value={note}
            onChange={(e) => setNote(e.target.value)}/>

          <DialogActions className='sm:mt-4'>
            <Button variant={"contained"} onClick={saveNote} color={"primary"} startIcon={<SaveRounded/>}>
                            Add Note
            </Button>
            <Button variant={"contained"} onClick={closeHandler} color={"secondary"}
              startIcon={<CancelRounded/>}>
                            Cancel
            </Button>
          </DialogActions>

        </DialogContent>


      </Dialog>
    </>
  );
}
