import React, { useEffect, useState } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Button, Popover } from "@material-ui/core";
import { DateTime } from "luxon";
import InvoiceQuickDetail from "./InvoiceQuickDetail";


// https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
const Table = ({ columns, data, yearChange, getInvoiceDetailUrl, popOverAnchorElement, popOverData, handlePopoverClose }) => {

  const thisYear = DateTime.now().get("year");

  const [filterInput, setFilterInput] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const skipPageResetRef = React.useRef(true);

  const years = React.useMemo(() => {
    let res = [];
    for (let i = 0; i < 3; i++) {
      res.push(thisYear - i);
    }
    res.reverse();
    return res;
  }, []);

  // useEffect(() => {
  //   console.log("Data updated, skipping table state updates");
  //   skipPageResetRef.current = true;
  // },[data]);

  // useEffect(() => {
  //   // After the table has updated, always remove the flag
  //   console.log("Resuming table state updates");
  //   skipPageResetRef.current = false;
  // });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data, initialState: {
    sortBy: [
      {
        id: "created",
        desc: true
      }
    ] },
  autoResetPage: !skipPageResetRef.current,
  autoResetExpanded: !skipPageResetRef.current,
  autoResetGroupBy: !skipPageResetRef.current,
  autoResetSelectedRows: !skipPageResetRef.current,
  autoResetSortBy: !skipPageResetRef.current,
  autoResetFilters: !skipPageResetRef.current,
  autoResetGlobalFilter: !skipPageResetRef.current,
  autoResetRowState: !skipPageResetRef.current,
  }, useGlobalFilter, useSortBy);

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilterInput(value);
    setGlobalFilter(value);
  };

  const handleYearSelect = year => {
    if (selectedYear === year) {
      yearChange(null);
      setSelectedYear(null);
    } else {
      yearChange(year);
      setSelectedYear(year);
    }
  };

  const mouseEnterCell = cell => {
    // if (cell.column.id !== "InspectionCount") {
    //   return;
    // }
    // mutate(`${config.api.baseUrl}/accounting/inspectorinvoice/${cell.row.original.Id}`,axios.get(`${config.api.baseUrl}/accounting/inspectorinvoice/${cell.row.original.Id}`), false);
  };

  const popOverOpen = Boolean(popOverAnchorElement);
  const popOverId = popOverOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    handleYearSelect(thisYear);
  },[]);

  return (
    <div className="tw-shadow-sm tw-border-b tw-border-gray-200 sm:tw-rounded-lg">
      <div className={"tw-flex tw-gap-8 tw-items-baseline"}>
        <p>Search</p>
        <input className={"tw-border-2 tw-rounded tw-flex-grow"}
          value={filterInput}
          onChange={handleFilterChange}
        />
        {years.map(year =>
          <Button key={year} variant={year === selectedYear ? 'contained' : 'text'}
            onClick={() => handleYearSelect(year)}>{year}</Button>
        )}
      </div>
      <table
        className="tw-mt-10 tw-min-w-full tw-divide-y tw-divide-gray-200"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, i) =>
            <tr key={`${i}`}
              className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) =>
                <th key={`${i}-${j}`} width={column.minWidth > 0 ? column.minWidth : null}
                  className={`tw-px-6 tw-py-3 tw-text-left tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider tw-font-bold 
                      ${column.isSorted ? column.isSortedDesc ? `sort-desc` : `sort-asc` : ``}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")} {
                    column.isSorted
                      ? column.isSortedDesc
                        ? <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-down"/></div>
                        : <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-up"/></div>
                      : ''
                  }
                </th>
              )}
            </tr>
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={`${i}`}{...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  let classnames = "tw-px-6 tw-py-2 ";
                  i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                  return (
                    <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()} onMouseEnter={() => mouseEnterCell(cell)}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Popover
        id={popOverId}
        open={popOverOpen}
        anchorEl={popOverAnchorElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popOverData && <InvoiceQuickDetail item={popOverData} getInvoiceDetailUrl={getInvoiceDetailUrl}/>}
      </Popover>
    </div>
  );
};

// Table.propTypes = {

// }

export default Table;
