import React, { useEffect, useRef, useState } from 'react';
import { apiInspectors } from "../api/inspectors";
import { apiInspections } from "../api/inspections";
import { Loader } from 'google-maps';
import { apiReports } from "../api/reports";
import { CircularProgress } from "@material-ui/core";

const formatDate = date => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

const HeatMap = () => {

  const [google, setGoogle] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapInfoWindow, setMapInfoWindow] = useState(null);
  const [data, setData] = useState(null);
  // Add state variables for 'From' and 'To' dates

  const getInitialFromDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date.toISOString().split('T')[0];
  };

  const getInitialToDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  // Add state variables for 'From' and 'To' dates with initial state
  const [fromDate, setFromDate] = useState(getInitialFromDate());
  const [toDate, setToDate] = useState(getInitialToDate());
  const [heatmapStore, setHeatmapStore] = useState(null);

  // Add state variable for loading
  const [loading, setLoading] = useState(false);

  const mainDiv = useRef();

  const options = {
    libraries: ['visualization'],
  };
  const loader = new Loader('AIzaSyBHDVtQHIMXzOr7xjqadRZtScytVh__xfA', options);

  const setMainDivSize = () => {
    if (!mainDiv.current) {
      return;
    }
    const height = window.innerHeight - mainDiv.current.offsetTop - 50;
    mainDiv.current.style.height = `${height}px`;
  };

  const initMap = async () => {
    const google = await loader.load();
    setGoogle(google);
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 25.782479, lng: -80.2706578 },
      zoom: 8,
      mapId: '8041c9b566fb264'
    });
    setMap(map);
    setMapInfoWindow(new google.maps.InfoWindow());
    window.onresize = () => {
      setMainDivSize();
    };
    setMainDivSize();
    return { map,google };
  };

  useEffect(() => {
    initMap();
  },[]);

  useEffect(() => {
    const initData = async () => {
      if (!map) {
        return;
      }

      let heatmapData = [];

      setLoading(true); // Set loading to true before data fetching starts
      const data = await apiReports.getZipCodeHeatMap(fromDate,toDate);
      data.data.result.forEach(datapoint => {
        // map.data.addGeoJson(datapoint.feature);
        heatmapData.push({
          location: new google.maps.LatLng(datapoint.feature.features[0].geometry.coordinates[1], datapoint.feature.features[0].geometry.coordinates[0]),
          weight: datapoint.count
        });
      });

      if (heatmapStore) {
        heatmapStore.setMap(null);
      }
      let heatmap = new google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        map: map,
        radius: 0.1, // Adjust the radius as needed
        dissipating: false, // Change if you want heat to dissipate over distance
        maxIntensity: 10, // Set maximum intensity (optional)
        opacity: 0.4 // Set the opacity of the heatmap layer
      });
      setHeatmapStore(heatmap);
      setLoading(false); // Set loading to false after data fetching completes
    };

    initData();


  }, [map,fromDate,toDate]);

  useEffect(() => {
    updateMapBounds();
    setMainDivSize();
  }, [map,data]);


  const updateMapBounds = async () => {
    if (!map || !data) {
      return;
    }
    // markers.forEach(marker => marker.setMap(null));
    // const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    // const markerList = [];
    // if (map && plannedInspections) {
    //   const bounds = new window.google.maps.LatLngBounds();
    //   for (const inspection of plannedInspections) {
    //     // const marker = new AdvancedMarkerElement({
    //     //   map,
    //     //   position: { lat: inspection.lat, lng: inspection.lng },
    //     //   content: createPin(PinElement, inspection).element
    //     // });
    //     const marker = createMarker(AdvancedMarkerElement, inspection);
    //     markerList.push(marker);
    //     bounds.extend(marker.position);
    //   }
    //   map.fitBounds(bounds);
    //   setMarkers(markerList);
    // }

    // console.log({ data });
    const allDatapoints = data.map(datapoint => datapoint.feature.features[0].geometry.coordinates);
    console.log({ allDatapoints });
    const bounds = new google.maps.LatLngBounds();
    allDatapoints.forEach(datapoint => {
      bounds.extend(new google.maps.LatLng(datapoint[1], datapoint[0]));
    });
    map.fitBounds(bounds);

  };

  function mouseOverInspection(inspection) {
    // make map pin expand
    const pin = document.getElementById(`ins-${inspection.id}`);
    // pin.style.width = "2rem";
    // pin.style.height = "2rem";
    pin.style.fontSize = "1.4rem";
    pin.style.padding = "0.9rem";
    pin.style.border = "2px solid black";
    pin.style.boxShadow = "0 0 15px 10px rgba(0, 0, 0, 0.3)";
    pin.style.zIndex = "1000";
  }

  function mouseOutInspection(inspection) {
    // make map pin shrink
    const pin = document.getElementById(`ins-${inspection.id}`);
    if (pin) {
      pin.style.padding = "8px 8px";
      pin.style.fontSize = "0.8rem";
      pin.style.border = "none";
      pin.style.boxShadow = "none";
      pin.style.zIndex = "0";
    }
  }


  // Add date selectors in the return statement
  return (
    <div>
      <h1>Inspection Areas</h1>

      <div className="tw-flex tw-gap-8 tw-mb-4 tw-items-baseline">
        <div className="tw-flex tw-gap-2 tw-items-baseline">
          <label>
              From:
            <input
              className="tw-rounded-lg"
              type="date"
              value={fromDate}
              onChange={e => setFromDate(e.target.value)}
            />
          </label>
          <label>
              To:
            <input
              className="tw-rounded-lg"
              type="date"
              value={toDate}
              onChange={e => setToDate(e.target.value)}
            />
          </label>
        </div>
      </div>

      <div id="map" ref={mainDiv}
        className="tw-my-4 2xl:tw-my-0 tw-shadow tw-overflow-hidden tw-rounded-xl tw-w-full tw-col-span-2 tw-max-h-screen">
      </div>

      {loading &&
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-flex tw-justify-center tw-items-center">
            <CircularProgress/>
          </div>
      }

    </div>
  );
};

export default HeatMap;