import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, FormControlLabel, InputAdornment,
  Modal,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { CancelRounded, Save, SaveRounded } from "@material-ui/icons";

export default function EditInspectionPriceModal({ inspectionType, open, closeHandler, saveHandler }) {

  if (!inspectionType) {
    return null;
  }

  const [price, setPrice] = useState(null);
  const [updateAll, setUpdateAll] = useState(false);

  useEffect(() => {
    setUpdateAll(false);
  },[open]);

  const savePrice = () => {
    saveHandler(inspectionType, price, updateAll);
    closeHandler();
  };

  useEffect(() => {
    if (!inspectionType) {
      return;
    }
    setPrice(inspectionType.Price);
  },[inspectionType]);

  return (
    <>
      <Dialog open={open} onClose={closeHandler} maxWidth='sm' fullWidth={true}>

        <DialogContent>
          <DialogContentText>
                        Edit Price for {inspectionType.InspectionType}
          </DialogContentText>

          <div className="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
            <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
              <span className="tw-text-gray-500 sm:tw-text-sm">$</span>
            </div>
            <input
              type="text"
              className="focus:tw-ring-primary focus:tw-border-primary tw-block tw-w-full tw-pl-7 tw-pr-12 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
              aria-describedby="price-currency"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
              <span className="tw-text-gray-500 sm:tw-text-sm" id="price-currency">
            USD
              </span>
            </div>
          </div>

          <FormControlLabel
            control={<Checkbox value={updateAll} onChange={(e) => {setUpdateAll(e.target.checked);}}/>}
            label="Update all"
          />

          <p className={"tw-text-lg tw-text-danger"}>{updateAll ? <span>This will update every Inspection Type price to ${price} - are you sure?</span> : <span>&nbsp;</span>}</p>

          <DialogActions className='sm:mt-4'>
            <Button variant={"contained"} onClick={savePrice} color={"primary"} startIcon={<SaveRounded/>}>
                            Save
            </Button>
            <Button variant={"contained"} onClick={closeHandler} color={"secondary"}
              startIcon={<CancelRounded/>}>
                            Cancel
            </Button>
          </DialogActions>

        </DialogContent>

      </Dialog>
    </>
  );
}
