/* eslint-disable react/display-name */

import React, { useEffect, useMemo, useState } from 'react';
import useSWR from "swr";
import { config } from "../core";
import { dateToString } from "../utilities/dateTime";
import 'chartjs-plugin-colorschemes';
import { useSortBy, useTable } from "react-table";
import { DateTime } from "luxon";
import { CircularProgress } from "@material-ui/core";
import DashboardCard from "../components/cards/DashboardCard";


const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns, data, initialState: {
      sortBy: [
        {
          id: "CreatedAt",
          desc: false
        }
      ]
    },
  }, useSortBy);
  return (
    <table
      className="tw-mt-10 tw-min-w-full tw-divide-y tw-divide-gray-200"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup, i) =>
          <tr key={`${i}`}
            className={"tw-bg-fs-dark-blue tw-bg-opacity-25 tw-rounded-t-lg"} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) =>
              <th key={`${i}-${j}`} width={column.minWidth > 0 ? column.minWidth : null}
                className={`tw-px-6 tw-py-3 tw-text-left tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider tw-font-bold 
                      ${column.isSorted ? column.isSortedDesc ? `sort-desc` : `sort-asc` : ``}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")} {
                  column.isSorted
                    ? column.isSortedDesc
                      ?
                      <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-down"/>
                      </div>
                      : <div className="tw-absolute tw-inline tw--mt-1 tw-ml-1"><i className="fa fa-sort-up"/>
                      </div>
                    : ''
                }
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={`${i}`}{...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                let classnames = "tw-px-6 tw-py-2 ";
                i % 2 ? classnames = classnames + "tw-bg-gray-200" : null;
                return (
                  <td key={`${i}-${j}`} className={classnames} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default function InspectionTimeCreated() {

  const columns = [
    { Header: "N/R", accessor: "PolicyStatus", Cell: props => props.row.original.PolicyStatus === "new" ? "N" : "R" },
    {
      Header: "Policy #", accessor: "PolicyNo",
      Cell: props => {
        return (
          <a target="_blank" rel="noreferrer"
            href={`/inspections/${props.row.original.PolicyNo}/process/pre_r`}>{props.row.original.PolicyNo}</a>
        );
      }
    },
    { Header: "Address", accessor: "Address" },
    {
      Header: "Effective",
      accessor: "EffectiveDate",
      Cell: props => dateToString(new Date(props.row.original.EffectiveDate))
    },
    {
      Header: "Received",
      accessor: "CreatedAt",
      Cell: props => dateToString(new Date(props.row.original.CreatedAt))
    },
    {
      Header: "Completed",
      accessor: "CompletedAt",
      Cell: props => dateToString(new Date(props.row.original.CompletedAt))
    },
    {
      Header: "Uploaded",
      accessor: "SubmittedAt",
      Cell: props => dateToString(new Date(props.row.original.SubmittedAt))
    },
    { Header: "Compl. Time", accessor: "CompletionDays" },
    {
      Header: "Upload Time", accessor: "UploadDays",
      Cell: props => <span
        className={props.row.original.UploadDays > 30 ? "tw-text-danger" : ""}>{props.row.original.UploadDays}</span>
    },
    {
      Header: "Effective -> Upload", accessor: "EffectiveUploadDays",
      sortType: (rowA, rowB, id, desc) => {
        const a = Number(rowA.original.EffectiveUploadDays);
        const b = Number(rowB.original.EffectiveUploadDays);
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      }
    },
  ];

  const [tableData, setTableData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(`${DateTime.now().year}-${DateTime.now().month}`);

  const { data } = useSWR(`${config.api.baseUrl}/reports/inspectiontimecreated?month=${selectedMonth}`, {
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data) {
      setDataLoading(false);
    }
    if (data && data.result) {
      setTableData(data.result);
    }
  }, [data]);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const monthSelections = useMemo(() => {
    let res = [];
    let currentYear = DateTime.now().year;
    let currentMonth = DateTime.now().month;

    for (let year = 2016; year <= currentYear; year++) {
      for (let month = 1; month <= 12; month++) {
        res.push({ id: `${year}-${month}`, display: `${monthNames[month-1]} ${year}` });
      }
    }
    return res;
  });

  function changeMonth(value) {
    setDataLoading(true);
    setSelectedMonth(value);
  }

  return (
    <>
      <h1>Inspection Time Report</h1>

      <div className="tw-mt-10 tw-flex tw-gap-4 tw-text-lg tw-items-center tw-h-14">
        <p>Reporting period</p>
        <select className={"tw-rounded-lg"} value={selectedMonth} onChange={(e) => changeMonth(e.target.value)}>
          {monthSelections.map(monthSelection =>
            <option key={monthSelection.id} value={monthSelection.id}>{monthSelection.display}</option>
          )}
        </select>
        {dataLoading && <CircularProgress color={"primary"}/>}
      </div>

      <div className={"tw-grid tw-grid-cols-4 tw-gap-16 tw-my-8"}>
        <DashboardCard title={"Received"} value={tableData && tableData.Summary.Received}/>
        <DashboardCard title={"Submitted"} value={tableData && `${tableData.Summary.Submitted} (${tableData.Summary.SubmittedPercent.toFixed(1)}%)`}/>
        <DashboardCard title={"Late"} value={tableData && `${tableData.Summary.Late} (${tableData.Summary.LatePercent.toFixed(1)}%)`}/>
        <DashboardCard title={"Average turnaround"} value={tableData && `${tableData.Summary.AverageTurnaround.toFixed(1)} days`}/>
      </div>

      <Table columns={columns} data={tableData && tableData.Data || []}/>
    </>
  );


}