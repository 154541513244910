import React, { useState } from "react";
import { Grid, Paper, TextField } from "@material-ui/core";

const ProfilePersonalInfoCard = ({ user }) => {
  return (
    <>
      <Grid container>
        <Grid item md={3}>
          {/*<img src={testImage} alt="" />*/}
        </Grid>
        <Grid item md={9}>
          <Paper style={{ padding: 16, marginBottom: 16 }}>
            <h2 className="tw-border-b-2 tw-pb-4 tw-font-black">
              Personal Info
            </h2>
            <form>
              <Grid container spacing={2} alignItems="space-between">
                <Grid item sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        label="Name"
                        value={`${user.name.first} ${user.name.last}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="Line 1"
                        value={`${user.address.line1}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="Line 2"
                        value={`${user.address.line2}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="City"
                        value={`${user.address.city}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="State"
                        value={`${user.address.state}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="Postal"
                        value={`${user.address.zip}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="D.O.B."
                        value={`${user.birthday}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="SSN"
                        value={`${user.social}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        label="Email"
                        value={`${user.email}`}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={12} md={6}>
                  <h3 className="tw-font-black">Bank Details</h3>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField label="Account" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField label="Routing" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField label="Account Type" fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilePersonalInfoCard;
