import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { config } from "~/core";
import Table from "../components/tables/Table";
import InspectionPreAddNoteModal from "../components/Modal/InspectionPreAddNote";
import { apiProvider } from "../api/core/provider";
import EditInspectionPriceModal from "../components/Modal/EditInspectionPrice";
import axios from "axios";
import { inspections } from "../api/inspections";
import formatMoney from "../utilities/formatMoney";
import { MenuItem, Select } from "@material-ui/core";

export default function SoftwareInvoicePrices() {

  const BASE_URL = "/api/" + config.api.version;

  const editPrice = type => {
    setEditingInspectionType(type);
    setEditPriceOpen(true);
  };

  const savePriceHandler = (inspectionType, newPrice, updateAll) => {
    apiProvider.post(`accounting/prices/software/${selectedCompany}`,
      {
        type: inspectionType.ID,
        price: Number(newPrice * 100),
        updateAll: updateAll
      }).then((data) => {
      mutate(`${BASE_URL}/accounting/prices/software/${selectedCompany}`);
    }
    ).catch((e) => {
      console.log("Error saving price - ", e);
    });
  };

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [editingInspectionType, setEditingInspectionType] = useState(null);
  const [editPriceOpen, setEditPriceOpen] = useState(false);
  const [priceTable, setPriceTable] = useState(null);

  const cols = [
    { Header: "Inspection Type", accessor: "InspectionType" },
    { Header: "Price", accessor: "FormattedPrice" },
    { Header: "", accessor: "edit" },
  ];

  const dataLoaded = (data, key, config) => {
    if (!data) {
      return;
    }
    if (!data || !data.result) {
      return;
    }
    let prices = JSON.parse(JSON.stringify(data.result));
    prices.sort((a, b) => {
      if (a.InspectionType < b.InspectionType) {
        return -1;
      }
      if (a.InspectionType > b.InspectionType) {
        return 1;
      }
      return 0;
    });
    let formattedPrices = prices.map(inspectionType => {
      if (Number(inspectionType.Price) > 0) {
        inspectionType.FormattedPrice = formatMoney(Number(inspectionType.Price) / 100);
        inspectionType.Price = Number(inspectionType.Price) / 100;
      } else {
        inspectionType.FormattedPrice = 0;
      }
      inspectionType.edit =
                <button
                  className="btn btn-primary"
                  onClick={() => editPrice(inspectionType)}
                >
                    Edit
                </button>;

      return inspectionType;
    });
    setPriceTable(formattedPrices);
  };

  const { data: companies } = useSWR(`${BASE_URL}/companies`, { refreshInterval: 0 });
  useSWR(() => selectedCompany ? `${BASE_URL}/accounting/prices/software/${selectedCompany}` : null, { onSuccess: dataLoaded });

  return (
    <>
      <h1>Software Invoice Prices</h1>

      <div className={"tw-mt-10 tw-flex tw-gap-8 tw-text-lg tw-items-baseline"}>
        <p>Company:</p>
        {companies && companies.result &&
        <select className="tw-flex-grow tw-rounded-lg"
          onChange={(e) => setSelectedCompany(e.target.value)}>
          <option value={0}>Choose company..</option>
          {companies.result.map((company) =>
            <option key={company.Id} value={company.Id}>{company.Name}</option>
          )}
        </select>
        }
      </div>

      {priceTable &&
            <div className={"tw-mt-14 tw-mb-10"}>
              <Table data={priceTable} columns={cols}/>
            </div>
      }

      <EditInspectionPriceModal inspectionType={editingInspectionType} open={editPriceOpen}
        closeHandler={() => setEditPriceOpen(false)}
        saveHandler={savePriceHandler}/>

    </>
  );
}