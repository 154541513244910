import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    '& > *': {
      marginLeft: '1rem',
      marginTop: '-0.4rem',
      cursor: 'pointer'
    },
  },
}));

export default function SimpleTwoColTable(props) {

  const materialClasses = useStyles();

  let rows = [];

  for (let i = 0; i < props.data.length; i++) {
    let row = props.data[i];
    if (props.skipEmpty && !row.b && !props.icon) {
      continue;
    }
    let rowBackgroundClass = i % 2 === 0 ? 'tw-bg-white' : 'tw-bg-gray-100';
    let classes = rowBackgroundClass.concat(" tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6");
    if (row.a) {
      rows.push(
        <div key={row.a}
          className={classes}>
          <dt className="tw-text-sm tw-font-medium tw-text-gray-600">
            {row.a}
          </dt>
          <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2 tw-flex">
            <div className="tw-flex-grow">
              {row.b}
            </div>
            {row.icon &&
              <div>
                <span className={materialClasses.icon} onClick={row.onClick}>{row.icon}</span>
              </div>
            }
          </dd>

        </div>
      );
    }
  }

  return (
    <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg">
      {props.title &&
            <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg">
              <div className="tw-px-4 tw-py-5 sm:tw-px-6">
                <div className={"tw-flex tw-items-baseline tw-justify-between"}>
                  <h3 className="tw-text-lg lg:tw-text-xl tw-leading-6 tw-font-medium tw-text-gray-900" >
                    {props.title}
                  </h3>
                  <div>{props.rightTitleComponent && props.rightTitleComponent}</div>
                </div>
                {props.subtitle &&
                    <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-500">
                      {props.subtitle}
                    </p>
                }
              </div>
            </div>
      }
      <div className="tw-border-t tw-border-gray-200">
        <dl>
          {rows}
        </dl>
      </div>
    </div>
  );


}