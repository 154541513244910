import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { config } from "~/core";
import Table from "../components/tables/Table";
import InspectionPreAddNoteModal from "../components/Modal/InspectionPreAddNote";
import { apiProvider } from "../api/core/provider";
import EditInspectionPriceModal from "../components/Modal/EditInspectionPrice";
import axios from "axios";
import { inspections } from "../api/inspections";
import formatMoney from "../utilities/formatMoney";

export default function InspectionPrices() {

  const BASE_URL = "/api/" + config.api.version;

  const editPrice = type => {
    setEditingInspectionType(type);
    setEditPriceOpen(true);
  };

  const savePriceHandler = (inspectionType, newPrice, updateAll) => {
    apiProvider.post(`accounting/prices`,
      {
        type: inspectionType.ID,
        price: Number(newPrice*100),
        updateAll: updateAll
      }).then((data) => {
      mutate(`${BASE_URL}/accounting/prices`);
    }
    ).catch((e) => {
      console.log("Error saving price - ",e);
    });
  };

  const [editingInspectionType, setEditingInspectionType] = useState(null);
  const [editPriceOpen, setEditPriceOpen] = useState(false);
  const [priceTable, setPriceTable] = useState(null);

  const cols = [
    { Header: "Inspection Type", accessor: "InspectionType" },
    { Header: "Price", accessor: "FormattedPrice" },
    { Header: "", accessor: "edit" },
  ];

  const { data: companies } = useSWR(`${BASE_URL}/companies`);
  const { data: inspectionTypePrices } = useSWR(`${BASE_URL}/accounting/prices`);

  useEffect(() => {
    console.log("inspectionTypePrices changed");
    if (!inspectionTypePrices || !inspectionTypePrices.result) {
      return;
    }
    let prices = JSON.parse(JSON.stringify(inspectionTypePrices.result));
    prices.sort((a,b) => {
      if (a.InspectionType < b.InspectionType) {
        return -1;
      }
      if (a.InspectionType > b.InspectionType) {
        return 1;
      }
      return 0;
    });
    let formattedPrices = prices.map(inspectionType => {
      if (Number(inspectionType.Price) > 0) {
        inspectionType.FormattedPrice = formatMoney(Number(inspectionType.Price) / 100);
        inspectionType.Price = Number(inspectionType.Price) / 100;
      } else {
        inspectionType.FormattedPrice = 0;
      }
      inspectionType.edit =
                <button
                  className="btn btn-primary"
                  onClick={() => editPrice(inspectionType)}
                >
                    Edit
                </button>;

      return inspectionType;
    });
    setPriceTable(formattedPrices);
  }, [inspectionTypePrices]);

  return (
    <>
      <h1>Inspection Prices</h1>

      {priceTable &&
            <div className={"tw-mt-14 tw-mb-10"}>
              <Table data={priceTable} columns={cols}/>
            </div>
      }

      <EditInspectionPriceModal inspectionType={editingInspectionType} open={editPriceOpen}
        closeHandler={() => setEditPriceOpen(false)}
        saveHandler={savePriceHandler}/>

    </>
  );
}