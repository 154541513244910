/* eslint-disable react/display-name */

import React, { useMemo } from 'react';
import { config } from "../core";
import DashboardCard from "../components/cards/DashboardCard";
import FSButton from "../components/Buttons/FSButton";
import InspectionReportTable from "../components/tables/InspectionReportTable";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";


export default function LateReportList() {

  const reportType = config.routeParams.type;

  const reportTypeTitle = useMemo(() => {
    switch (reportType) {
    case "allopen":
      return "All Open Inspections";
    case "comingdue":
      return "Inspections Coming Due (in 5 days)";
    case "late":
      return "Inspections Late";
    case "late30":
      return "Inspections Late 30-45 days";
    case "late45":
      return "Inspections Late > 45 days";
    case "excluded":
      return "Inspections excluded due to ongoing access issues";
    }
  }, [reportType]);


  const { data, mutate } = useSWRImmutable(`${config.api.baseUrl}/reports/latereportlist?type=${reportType}`, {
    refreshInterval: 0,
  });


  return (
    <>
      <h1>Late Report Inspection List</h1>
      <div className={"tw-flex tw-justify-between"}>
        <h3>{reportTypeTitle}</h3>
        <FSButton icon={<i className={"fa fa-download"}/>} color={"primary"}
          onClick={() => window.open(`/reports/late/list/csv/${reportType}`)}>Download CSV</FSButton>
      </div>

      <InspectionReportTable data={data?.result} mutate={mutate}/>

    </>

  );

}