import { ApiCore } from "./core";

const url = "test";

const apiTest = new ApiCore({
  list: false,
  get: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

export { apiTest };
