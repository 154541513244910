import { ApiCore } from "./core";
import { apiProvider } from "./core/provider";

const url = "reports";

const apiReports = new ApiCore({
  list: false,
  get: false,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

apiReports.getZipCodeHeatMap = function(from, to) {
  const fromTimestamp = new Date(from).getTime();
  const toTimestamp = new Date(to).getTime();
  return apiProvider.get(`${url}/zipCodeHeatMap?from=${fromTimestamp}&to=${toTimestamp}`);
};

export { apiReports };
