import React, { useState, useEffect } from "react";
import { apiProvider } from "../api/core/provider";
import { CancelRounded, MoreVert, SaveRounded } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { Button, Dialog, DialogActions, DialogContent, TextareaAutosize } from "@material-ui/core";
import DashboardCard from "../components/cards/DashboardCard";
import DropdownButton from "../components/Buttons/DropdownButton";
import InspectionHistoryTable from "../components/tables/InspectionHistoryTable";
import LoadingAnimations from "../components/Loaders/Skeleton";
import { dateToString } from "../utilities/dateTime";
import { ToastContainer } from "react-toastify";

export default function InactiveInspections() {

  const severityColors = ["primary", "warning", "danger"];

  const includeTheseSoThatPurgeCSSDoesntBinThem = [
    'tw-text-primary', 'tw-text-warning', 'tw-text-danger', 'tw-bg-primary', 'tw-bg-warning', 'tw-bg-danger'
  ];

  const quickActions = ["Insured is away", "Called insured - no answer", "Wrong or no contact number for insured","Missing / incorrect property address","No gate code or no access granted to gated community"];

  const [inactiveList, setInactiveList] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [inspectionSelected, setInspectionSelected] = useState({});
  const [inspectionHistory, setInspectionHistory] = useState(null);
  const [severityCounts, setSeverityCounts] = useState([null, null, null]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const getInspectionHistory = policyNo => {
    setInspectionHistory(null);
    apiProvider.get("inspection/" + policyNo + "/history", undefined).then(r => {
      console.log({ r });
      let history = r.data.result;
      history.sort((a, b) => {
        if (new Date(a.HistoryTime) < new Date(b.HistoryTime)) {
          return 1;
        } else if (new Date(a.HistoryTime) > new Date(b.HistoryTime)) {
          return -1;
        }
        return 0;
      });
      setInspectionHistory(history);
    });
  };

  const showInspectionClick = (inspection) => {
    getInspectionHistory(inspection.policyNo);
    setInspectionSelected(inspection);
    setInfoDialogOpen(true);
  };

  const addQuickNote = (inspection, note) => {
    console.log("Adding note '" + note + "' to " + inspection.policyNo);
    // postNewNote(inspection.policyNo, note).then(r => {
    //   if (r.status < 399) {
    //     toast.success("Quick Note added", {
    //       autoClose: 2000
    //     });
    //     // remove policy from list
    //     const allInspections = inactiveList.filter((item) => {
    //       return item.PolicyNo !== inspection.policyNo;
    //     });
    //     setInactiveList(allInspections);
    //   } else {
    //     console.log("Error adding quick note - "+r.data.message);
    //     toast.error('Unable to add note - please try again later');
    //   }
    // });
  };

  const getPrettyStatus = status => {
    switch (status) {
    case "new":
      return "New";
    case "inprogress":
      return "In Progress";
    case "noaccess":
      return "No Access";
    case "completed":
      return "Completed";
    }
    return status;
  };

  useEffect(() => {
    if (inactiveList === null) {
      return;
    }

    inactiveList.sort((a, b) => {
      if (a.Severity < b.Severity) {
        return 1;
      } else if (a.Severity > b.Severity) {
        return -1;
      }
      if (a.AccessAttempts < b.AccessAttempts) {
        return -1;
      } else if (a.AccessAttempts > b.AccessAttempts) {
        return 1;
      }
      if (new Date(a.LastNoteTime) < new Date(b.LastNoteTime)) {
        return -1;
      } else if (new Date(a.LastNoteTime) > new Date(b.LastNoteTime)) {
        return 1;
      }
      return 0;
    });

    let data = inactiveList.map((item) => {
      return {
        policyNo: item.PolicyNo,
        lastUpdate: dateToString(new Date(item.LastNoteTime)),
        lastNote: item.Section === 'import' ? '' : item.LastNote,
        address: item.Inspection.Address + ", " + item.Inspection.City,
        noUpdates: item.Section === 'import',
        name: item.Inspection.Name,
        accessAttempts: item.AccessAttempts,
        daysUntilLate: item.DaysUntilLate,
        severity: item.Severity,
        status: getPrettyStatus(item.Status),
      };
    }).filter((item) => {
      return selectedFilter === null || selectedFilter === item.severity;
    });

    setTableData(data);

  }, [inactiveList, selectedFilter]);

  useEffect(() => {
    if (inactiveList === null) {
      return;
    }
    let counts = [0, 0, 0];
    for (let i = 0; i < inactiveList.length; i++) {
      let inspection = inactiveList[i];
      counts[inspection.Severity - 1]++;
    }
    setSeverityCounts(counts);
  }, [inactiveList]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    apiProvider.get("inspections/inactive", undefined).then(r => {
      console.log({ r });
      setInactiveList(r.data.result);
      setLoaded(true);
    });
  };

  const filterButtonClick = selected => {
    console.log("Filter " + selected + " clicked");
    if (selected === selectedFilter) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(selected);
    }
  };

  return (
    <>
      <h3 className={"heading"}>Inactive Inspections</h3>
      <div>

        <div className={"tw-grid tw-grid-cols-3 tw-gap-2 sm:tw-gap-4 lg:tw-gap-12"}>
          <DashboardCard title={"Priority 1"} subtitle={"Immediate attention required"}
            value={severityCounts[2]} color={`tw-bg-${severityColors[2]}`}
            disabled={selectedFilter && selectedFilter !== 3}
            onClick={() => filterButtonClick(3)}/>
          <DashboardCard title={"Priority 2"} subtitle={"Please contact soon"} value={severityCounts[1]}
            color={`tw-bg-${severityColors[1]}`}
            disabled={selectedFilter && selectedFilter !== 2}
            onClick={() => filterButtonClick(2)}/>
          <DashboardCard title={"Priority 3"} subtitle={"Due in two weeks"} value={severityCounts[0]}
            color={`tw-bg-${severityColors[0]}`}
            disabled={selectedFilter && selectedFilter !== 1}
            onClick={() => filterButtonClick(1)}/>
        </div>

        {loaded &&
                <>
                  <div className="tw-flex tw-flex-col tw-mt-8">
                    <div className="tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8 tw-min-w-full">
                      <div
                        className="tw-py-2 tw-align-middle tw-inline-block tw-min-w-full sm:tw-px-6 lg:tw-px-8">
                        <div
                          className="tw-shadow tw-overflow-hidden tw-border-b tw-border-gray-200 sm:tw-rounded-lg">
                          <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
                            <thead className="tw-bg-gray-50">
                              <tr>
                                <th scope="col"><i className="fal fa-phone-alt"/></th>
                                <th scope="col"
                                  className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-700 tw-uppercase tw-tracking-wider">
                                                Policy No
                                </th>
                                <th scope="col"
                                  className="tw-px-6 md:tw-hidden tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-700 tw-uppercase tw-tracking-wider">
                                                Policy
                                </th>
                                <th scope="col"
                                  className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-700 tw-uppercase tw-tracking-wider tw-w-48">
                                                Address
                                </th>
                                <th scope="col"
                                  className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-700 tw-uppercase tw-tracking-wider tw-w-48">
                                                Status
                                </th>
                                <th scope="col"
                                  className="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-700 tw-uppercase tw-tracking-wider tw-w-48">
                                                Last Updated
                                </th>
                                <th scope="col"
                                  className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-700 tw-uppercase tw-tracking-wider">
                                                Last note
                                </th>
                                <th scope="col" className="tw-relative tw-px-6 tw-py-3 tw-w-48">
                                  <span className="tw-sr-only">More info</span>
                                </th>
                                <th scope="col" className="tw-relative tw-px-6 tw-py-3 tw-w-48">
                                  <span className="tw-sr-only">Actions</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>

                              {tableData.map((item, index) =>
                                <tr key={item.policyNo}
                                  className={`${index % 2 === 0 ? 'tw-bg-white' : 'tw-bg-gray-200'}`}>
                                  <td>
                                    <span className="fa-stack">
                                      <span
                                        className={`tw-text-${severityColors[item.severity - 1]} fas fa-triangle fa-stack-2x`}/>
                                      <span
                                        className="fa-stack-1x tw-text-dark tw-pt-1 tw-text-white">{item.accessAttempts}</span>
                                    </span>
                                  </td>
                                  <td className="md:tw-hidden tw-px-6 tw-py-4 tw-whitespace-pre tw-text-xs tw-font-medium tw-text-gray-900">
                                    <a href={`/inspections/${item.policyNo}/process/pre_r`}>{item.policyNo}</a><br/>{item.address}
                                  </td>
                                  <td className="tw-hidden md:tw-table-cell tw-px-6 tw-py-4 tw-whitespace-pre tw-text-xs tw-font-medium tw-text-gray-900">
                                    <a href={`/inspections/${item.policyNo}/process/pre_r`}>{item.policyNo}</a>
                                  </td>
                                  <td className="tw-hidden md:tw-table-cell tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-xs tw-text-gray-600">
                                    {item.address}
                                  </td>
                                  <td className="tw-hidden md:tw-table-cell tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-xs tw-text-gray-600">
                                    {item.status}
                                  </td>
                                  <td className="tw-hidden md:tw-table-cell tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-xs tw-text-gray-600">
                                    {item.lastUpdate}
                                  </td>
                                  <td className="tw-px-6 tw-py-4 tw-text-xs tw-text-gray-600">
                                    {item.noUpdates ? <span
                                      className={"tw-text-danger"}>No activity</span> : item.lastNote}
                                  </td>
                                  <td className="tw-hidden md:tw-table-cell tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-xs tw-font-medium">
                                    <Button variant={'contained'} color={'primary'}
                                      startIcon={<MoreVert/>}
                                      onClick={() => showInspectionClick(item)}>Details</Button>
                                  </td>
                                  <td className="md:tw-hidden tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-right tw-text-xs tw-font-medium">
                                    <IconButton variant={'contained'}
                                      color={'primary'}
                                      onClick={() => showInspectionClick(item)}><MoreVert/></IconButton>
                                  </td>
                                  <td><DropdownButton title={"Action"} options={quickActions}
                                    onSelectOption={(optionSelected) => addQuickNote(item, optionSelected)}/>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
        }

        {loaded && tableData.length === 0 &&
                <div className={"tw-text-lg tw-mt-40 tw-flex"}>
                  <p className={"tw-mx-auto"}>Congratulations - no inactive inspections 🎉</p>
                </div>
        }

        {!loaded &&
                <>
                  <LoadingAnimations width={'100%'} height={30} count={7}/>
                </>
        }

        <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)} maxWidth='md' fullWidth={true}>
          <DialogContent>
            <div
              className={"tw-font-bold tw-text-lg lg:tw-text-2xl tw-mb-6"}>{inspectionSelected.policyNo} - {inspectionSelected.address} - {inspectionSelected.name}</div>

            <div className={"tw-text-xs"}>
              <InspectionHistoryTable items={inspectionHistory}/>
            </div>

            <TextareaAutosize autoFocus className="tw-w-full tw-mt-6" rowsMin={6} placeholder="Notes"
              onChange={(e) => {
              }}/>

            <DialogActions className='sm:tw-mt-4'>
              <Button variant={"contained"} color={"primary"} startIcon={<SaveRounded/>}>
                                Add Note
              </Button>
              <Button variant={"contained"} onClick={() => setInfoDialogOpen(false)} color={"secondary"}
                startIcon={<CancelRounded/>}>
                                Cancel
              </Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </>
  );

}