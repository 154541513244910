import React, { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { apiProvider } from "../api/core/provider";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiY29saW5uaWNoIiwiYSI6ImNrbHMxM2RoMzA4Y3cydnB2cTlncnNuN2kifQ.31ld9j_I5ILs2QaG5-Dalg';

export default function MapTesting() {

  const mapContainer = useRef();
  // const [lng, setLng] = useState(-80.191);
  // const [lat, setLat] = useState(25.774);
  // const [zoom, setZoom] = useState(12);
  // const [lng, setLng] = useState(-88.65599642142855);
  // const [lat, setLat] = useState(25.520666713456635);
  // const [zoom, setZoom] = useState(5.352);
  //
  const [inspections, setInspections] = useState(null);

  useEffect(() => {
    apiProvider.get("inspections/open", undefined).then(r => {
      // console.log({ r });
      let inspections = r.data.result;
      setInspections(inspections);
    });
  }, []);

  useEffect(() => {
    if (inspections === null) {
      return;
    }
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/colinnich/ckls14y0q119r17qpv4o5u3od',
    });
    map.addControl(new mapboxgl.NavigationControl());

    let coords1 = [180, 90];
    let coords2 = [-180, -90];

    for (let i = 0; i < inspections.length; i++) {
      let inspection = inspections[i];
      if (inspection.Lat !== 0 && inspection.Long !== 0) {
        new mapboxgl.Marker()
          .setLngLat([inspection.Long, inspection.Lat])
          .setPopup(new mapboxgl.Popup().setHTML(`
            <div class='tw-p-2'>
              <p class='tw-text-lg tw-font-bold tw-text-secondary'>
                ${inspection.PolicyNo}
              </p>
              <p class='tw-text-sm'>
                ${inspection.Name}
                <br>
                ${inspection.Address}, ${inspection.City}
              </p>
              <p class='tw-text-gray-600'>
                ${inspection.InspectionType}
              </p>
            </div>
          `))
          // .setPopup(new mapboxgl.Popup().setHTML("" +  + "</p></div>")) // add popup
          .addTo(map);
        coords1[0] = Math.min(coords1[0], inspection.Long);
        coords2[0] = Math.max(coords2[0], inspection.Long);
        coords1[1] = Math.min(coords1[1], inspection.Lat);
        coords2[1] = Math.max(coords1[1], inspection.Lat);
      }
    }
    // work out bounding box of all points
    let cameraPoint = map.cameraForBounds([coords1, coords2], {
      padding: { top: 40, bottom: 40, left: 40, right: 40 }
    });
    map.jumpTo(cameraPoint);
    return () => map.remove();
  }, [inspections]);

  return (

    <div className={"tw-h-full"} style={{ height: "1000px" }}>
      <div className="map-container tw-h-full" ref={mapContainer}/>
    </div>

  );

}