import React, { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import useSWRImmutable from 'swr/immutable';
import { config } from "../core";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiY29saW5uaWNoIiwiYSI6ImNrbHMxM2RoMzA4Y3cydnB2cTlncnNuN2kifQ.31ld9j_I5ILs2QaG5-Dalg';

const tabs = [
  { name: 'Inspections', href: '#' },
  { name: 'Inspectors', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AreaMapInspectors() {

  const inspectorsMapContainer = useRef();

  const [inspectorLocations, setInspectorLocations] = useState(null);

  const inspectorLocationsLoaded = (data, key, config) => {
    setInspectorLocations(data);
  };

  useSWRImmutable(`${config.api.baseUrl}/users/inspectorlocations`, { onSuccess: inspectorLocationsLoaded, refreshInterval: 0 });

  useEffect(() => {
    if (!inspectorLocations) {
      return;
    }
    const map = new mapboxgl.Map({
      container: inspectorsMapContainer.current,
      style: 'mapbox://styles/colinnich/ckls14y0q119r17qpv4o5u3od',
    });
    map.addControl(new mapboxgl.NavigationControl());

    let coords1 = [180, 90];
    let coords2 = [-180, -90];

    const counties = inspectorLocations.result;
    for (let i = 0; i < counties.length; i++) {
      let county = counties[i];
      console.log({ county });
      if (county.Lat !== 0 && county.Long !== 0) {
        new mapboxgl.Marker()
          .setLngLat([county.long, county.lat])
          .setPopup(new mapboxgl.Popup().setHTML(`
            <div class='tw-p-2'>
              <p class='tw-text-lg tw-font-bold tw-text-secondary'>
                ${county.county}, ${county.state}
              </p>
              <p class='tw-text-sm'>
              ${county.inspectors.join(", ")}
              </p>
            </div>
          `))
        // .setPopup(new mapboxgl.Popup().setHTML("" +  + "</p></div>")) // add popup
          .addTo(map);
        coords1[0] = Math.min(coords1[0], county.long);
        coords2[0] = Math.max(coords2[0], county.long);
        coords1[1] = Math.min(coords1[1], county.lat);
        coords2[1] = Math.max(coords1[1], county.lat);
      }
    }

    // work out bounding box of all points
    let cameraPoint = map.cameraForBounds([coords1, coords2], {
      padding: { top: 0, bottom: 0, left: 0, right: 0 }
    });
    map.jumpTo(cameraPoint);
    return () => map.remove();
  }, [inspectorLocations]);

  return (

    <>
      <div className={`tw-h-full`} style={{ height: "1000px" }}>
        <div className="map-container tw-h-full" ref={inspectorsMapContainer}/>
      </div>
    </>
  );

}