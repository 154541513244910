/* eslint-disable react/display-name */

import React, { useRef, useState, useMemo } from 'react';
import { config } from "../core";
import FSButton from "../components/Buttons/FSButton";
import axios from "axios";
import DashboardCard from "../components/cards/DashboardCard";
import InspectionReportTable from "../components/tables/InspectionReportTable";
import Table from "../components/tables/Table";


export default function PolicyListStatus(factory, deps) {

  const policyListForm = useRef();

  const notFoundCols = [
    {
      Header: "Policy #", accessor: "PolicyNo"
    }
  ];

  const [policyNumbers, setPolicyNumbers] = useState([]);
  const [results, setResults] = useState(null);

  const getStatus = () => {
    axios.post(`${config.api.baseUrl}/reports/statusfromlist`, policyNumbers).then((response) => {
      setResults(response.data.result);
    });
  };

  const notFoundMapped = useMemo(() => {
    if (!results) {
      return [];
    }
    return results.NotFounds?.map((policyNo) => {
      return { "PolicyNo": policyNo };
    });
  }, [results]);

  const dupesMapped = useMemo(() => {
    if (!results) {
      return [];
    }
    return results.Duplicates?.map((policyNo) => {
      return { "PolicyNo":policyNo };
    });
  },[results]);


  return (
    <>
      <h1>Policy List Status</h1>
      <div className={"tw-flex tw-justify-between"}>
        <h3>Get the status of a list of policies</h3>
        {results && <FSButton icon={<i className={"fa fa-download"}/>} color={"primary"}
          onClick={() => policyListForm.current.submit()}>Download CSV</FSButton>}
      </div>

      <form className={`${results!==null ? "tw-hidden":""}`} ref={policyListForm} method={"post"} action={"/reports/fromlist/csv"}>
        <textarea className={"tw-w-full tw-mt-10"} rows={20} name={"policies"}
          placeholder="Please enter a list of policy numbers&#10;POLICY001&#10;POLICY002&#10;POLICY003&#10;etc..."
          onChange={(e) => setPolicyNumbers(e.target.value)}>
        </textarea>
        <FSButton className={"tw-mt-6"} color={"primary"} onClick={() => getStatus()}>Get Status</FSButton>
      </form>

      {results &&
          <>

            <div className={"tw-grid tw-grid-cols-3 tw-gap-4 md:tw-gap-8 xl:tw-gap-16 tw-my-6"}>
              <DashboardCard title={"Requested"} value={results.Requested}/>
              <DashboardCard title={"Found"} value={results.Total}/>
              <DashboardCard title={"Submitted"} value={results.Data?.filter(item => item.Status === "Submitted").length}/>
              {/*<DashboardCard title={"Late"} value={`${results.Late} (${(results.Late / results.Total*100).toFixed(1)}%)`}/>*/}
            </div>

            <InspectionReportTable data={results.Data} mutate={getStatus} initialSort={"OrderID"}/>

            {results.NotFounds &&
                <>
                  <h2 className={"tw-mt-8"}>Not Found / Awaiting Order from API - {results.NotFounds.length}</h2>
                  <Table columns={notFoundCols} data={notFoundMapped}
                  />
                </>
            }

            {results.Duplicates &&
                <>
                  <h2 className={"tw-mt-8"}>Duplicates - {results.Duplicates.length}</h2>
                  <Table columns={notFoundCols} data={dupesMapped}
                  />
                </>
            }
          </>
      }

    </>
  );

}